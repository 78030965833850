import { apim, UserSignUp, HttpResponse } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import { AxiosRequestConfig } from 'axios';
import { getUserSearchPageWithRolesMapping } from '@/src/account/services/legacy-mapping-admin';
import { TempUser } from '@/src/core/types/interfaces';
import { getNotificationMapping } from '@/src/core/services/notification-mapping';
import { NotificationTypes } from '@/src/core/types/api';
import { SATAIR_BASESITE_ID } from '@/src/market/settings/baesiteId';
import Cookies from 'js-cookie';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class AdminApi {
  public static GetUsers = `${baseUrl}/api/admin/users`;
  public static async handleGetUsers(settings: AxiosRequestConfig) {
    const params = settings.params;
    const q = params.q ? encodeURI(params.q) : '';
    const size = params?.size ?? 20;
    const page = params?.p ?? 0;
    const enableFinanceSection = params.enableFinanceSection ?? false;
    const isFinanceBetaPage = params.isFinanceBetaPage ?? false;
    const globalId = Cookies.get('globalId')!;
    const asm = Boolean(Cookies.get('asm'));
    const betaCustomer = Cookies.get('betaCustomer') === 'true';
    const response = await apim.searchUsers1(SATAIR_BASESITE_ID, globalId, {
      asm,
      q,
      currentPage: page,
      pageSize: size,
      sort: 'name-asc',
      fields: 'FULL',
      globalId,
    });

    const data = getUserSearchPageWithRolesMapping(
      response.data,
      enableFinanceSection,
      isFinanceBetaPage,
      betaCustomer,
    );

    return toLegacyResponse(response, {
      Data: objectKeysToPascalCaseRecursive(data),
    });
  }
  public static AddUser = `${baseUrl}/api/admin/adduser`;
  public static async handleAddUser(user: TempUser) {
    const globalId = Cookies.get('globalId')!;
    const asm = Boolean(Cookies.get('asm'));
    const data: UserSignUp = {
      uid: user.Email,
      firstName: user.FirstName,
      lastName: user.LastName,
      password: user.Password,
    };
    const response = await apim.registerUser(SATAIR_BASESITE_ID, data, {
      asm,
      globalId,
    });

    const responseUser = response.data;

    if (responseUser.globalId) {
      if (user.IsAdmin) {
        await apim.enableAdmin(SATAIR_BASESITE_ID, responseUser.globalId, {
          asm,
          enableAdmin: 'true',
        });
      }

      if (user.IsReadOnly) {
        await apim.setReadOnly(SATAIR_BASESITE_ID, responseUser.globalId, {
          asm,
          readOnly: 'true',
        });
      }

      if (user.IsFinance) {
        await apim.setFinance(SATAIR_BASESITE_ID, responseUser.globalId, {
          asm,
          financeCustomer: 'true',
        });
      }
    }
    const notification = getNotificationMapping({
      title: 'Success',
      description: `User created for ${user.Email}`,
      type: NotificationTypes.Success,
    });

    const result = {
      data: { globalId: responseUser.globalId },
      notification: [notification],
    };

    return toLegacyResponse(response, objectKeysToPascalCaseRecursive(result));
  }

  public static AddUsers = `${baseUrl}/api/admin/addusers`;
  public static async handleAddUsers(settings: AxiosRequestConfig) {
    const users = settings.data as TempUser[];
    const globalId = Cookies.get('globalId')!;
    const asm = Boolean(Cookies.get('asm'));
    const notCreatedUsers: string[] = [];
    let tempUsers: TempUser[] = [];

    for (const user of users) {
      const data: UserSignUp = {
        uid: user.Email,
        firstName: user.FirstName,
        lastName: user.LastName,
        password: user.Password,
      };
      try {
        const response = await apim.registerUser(SATAIR_BASESITE_ID, data, {
          asm,
          globalId,
        });
        const responseUser = response.data;

        if (responseUser.globalId) {
          if (user.IsAdmin) {
            await apim.enableAdmin(SATAIR_BASESITE_ID, responseUser.globalId, {
              asm,
              enableAdmin: 'true',
            });
          }

          if (user.IsReadOnly) {
            await apim.setReadOnly(SATAIR_BASESITE_ID, responseUser.globalId, {
              asm,
              readOnly: 'true',
            });
          }

          if (user.IsFinance) {
            await apim.setFinance(SATAIR_BASESITE_ID, responseUser.globalId, {
              asm,
              financeCustomer: 'true',
            });
          }
        }
        user.Created = true;
      } catch (error) {
        notCreatedUsers.push(user.Email);
        user.Created = false;
      }
      tempUsers.push(user);
    }

    const createdUsersCount = users.length - notCreatedUsers.length;
    const partialSuccess = notCreatedUsers.length > 0;
    const notification = getNotificationMapping({
      title: partialSuccess ? `${createdUsersCount} of ${users.length} users created` : 'Success',
      description: partialSuccess
        ? `The following users already exists: ${notCreatedUsers.join(', ')}`
        : `All users successfully created`,
      type: NotificationTypes.Success,
    });

    const httpResponse = {
      data: null,
      status: 200,
    } as HttpResponse<unknown>;

    const result = {
      notification: [notification],
      data: tempUsers,
    };

    return toLegacyResponse(httpResponse, objectKeysToPascalCaseRecursive(result));
  }
}
