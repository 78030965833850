<template>
  <div ref="productWrapper">
    <slot v-bind="{ product, inView }"> </slot>
  </div>
</template>

<script setup lang="ts">
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { OwningSystem } from '@/src/core/types/api';
import { IProductAdditionalData } from '@/src/core/types/interfaces';
import { ResizeEventBus } from '@/src/core/utils/resize-event-bus';
import { ScrollEventBus } from '@/src/core/utils/scroll-event-bus';
import { userUserFetched } from '@/src/market/hooks/useUserFetched';
import { isBlueCustomer, isRedCustomer } from '@/src/market/services/user-service';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { useProductStore } from '@/src/market/stores/product';
import { useProductAdditionalInfoStore } from '@/src/market/stores/product-additional-info';
import { useProductFHSDetailsStore } from '@/src/market/stores/product-fhs-details';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { useProductRelationsStore } from '@/src/market/stores/product-relations';
import debounce from 'lodash/debounce';
import { computed, onUnmounted, ref, watch } from 'vue';

interface Props {
  id: string;
  fetchAdditionalData?: boolean;
  warehouseCode?: string;
  quantity?: number;
  checkInterchangeable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  fetchAdditionalData: true,
  warehouseCode: undefined,
  quantity: 0,
  checkInterchangeable: false,
});
const authenticationStore = useAuthenticationStore();
const productStore = useProductStore();
const productAdditionalInfoStore = useProductAdditionalInfoStore();
const productPriceInfoStore = useProductPriceInfoStore();
const productRelationsStore = useProductRelationsStore();
const airbusPartStore = useAirbusPartStore();
const productFHSStore = useProductFHSDetailsStore();
const { onIsUserFetched } = userUserFetched();

const inView = ref(false);
const additionalDataLoaded = ref(false);
const productWrapper = ref<HTMLElement>();

const product = computed(() => productStore.productById(props.id));

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const productAdditionalData = computed(() => {
  if (!product.value) {
    return null;
  }

  if (product.value.OwningSystem === OwningSystem.BLUE) {
    return airbusPartStore.airbusPartByOfferId(product.value.Id) || null;
  } else {
    return productAdditionalInfoStore.additionalDataByOfferId(product.value.Id) || null;
  }
});

const fetchQty = computed(() =>
  props.quantity > 0 ? props.quantity : product.value?.AddToBasketQty || 1,
);

const fetchProductAdditionalData = () => {
  if (
    isAuthenticated.value &&
    isInView() &&
    props.fetchAdditionalData &&
    !additionalDataLoaded.value
  ) {
    ScrollEventBus.$off('scroll', fetchAdditionalDataDebounced);
    ResizeEventBus.$off('resize', fetchAdditionalDataDebounced);
    inView.value = true;

    if (!product.value) {
      return;
    }

    if (product.value.OwningSystem === OwningSystem.BLUE) {
      if (isBlueCustomer()) {
        airbusPartStore.queueAirbusParts({
          OfferId: product.value.Id,
          Quantity: fetchQty.value,
        });

        if (product.value.hasFHS) {
          productFHSStore.queueAirbusFHS(product.value.Id);
        }

        additionalDataLoaded.value = true;
      }
    } else {
      if (isRedCustomer()) {
        const WarehouseCode = props.warehouseCode;
        productAdditionalInfoStore.queueAdditionalProductData({
          OfferId: product.value.Id,
          WarehouseCode,
          Quantity: fetchQty.value,
        });
        productPriceInfoStore.queueAdditionalProductData({
          OfferId: product.value.Id,
          Quantity: fetchQty.value,
        });
        additionalDataLoaded.value = true;
      }
    }
  }
};

const fetchAdditionalDataDebounced = debounce(() => {
  fetchProductAdditionalData();
}, 500);

const isInView = () => {
  const element = productWrapper.value;

  if (!element) {
    return false;
  }

  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom - rect.height <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

watch(
  productAdditionalData,
  (newValue: IProductAdditionalData, oldValue: IProductAdditionalData) => {
    if (!newValue && oldValue && oldValue.Id) {
      additionalDataLoaded.value = false;
      fetchProductAdditionalData();
      ScrollEventBus.$on('scroll', fetchAdditionalDataDebounced);
      ResizeEventBus.$on('resize', fetchAdditionalDataDebounced);
    }
  },
);

onIsUserFetched(() => {
  productStore.fetchAndUpdateProductByOfferId({ id: props.id, force: false }).then(() => {
    if (isAuthenticated.value) {
      fetchProductAdditionalData();
      ScrollEventBus.$on('scroll', fetchAdditionalDataDebounced);
      ResizeEventBus.$on('resize', fetchAdditionalDataDebounced);
    }

    if (props.checkInterchangeable && product.value?.OwningSystem !== OwningSystem.BLUE) {
      productRelationsStore.prefetchInterchangeables({
        sku: product.value?.Sku || '',
      });
    }
  });
});

onUnmounted(() => {
  ScrollEventBus.$off('scroll', fetchAdditionalDataDebounced);
  ResizeEventBus.$off('resize', fetchAdditionalDataDebounced);
});
</script>
