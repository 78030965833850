// tslint:disable
import { apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { getB2bUnitMapping } from '@/src/core/services/legacy-user-mapping';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import Cookies from 'js-cookie';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class UserApi {
  public static GetUserDetails = (userId: string) => `${baseUrl}/api/user/${userId}`;
  public static UpdateUserDetails = (userId: string, asAdmin: boolean) =>
    `${baseUrl}/api/user/${userId}/save?asAdmin=${asAdmin}`;
  public static UpdateUserLogin = (userId: string) => `${baseUrl}/api/user/${userId}/login`;
  public static UpdateUserPassword = (userId: string) => `${baseUrl}/api/user/${userId}/password`;
  public static DeleteUser = (userId: string) => `${baseUrl}/api/user/${userId}/delete`;
  public static ResetUserPassword = (userId: string) =>
    `${baseUrl}/api/user/forgotten?userid=${userId}`;
  public static ResetUserPasswordSet = () => `${baseUrl}/api/user/forgotten/reset`;
  public static GetUserUnit = (userId: string) => `${baseUrl}/api/user/units/${userId}`;
  public static PermissionDetails: string = `${baseUrl}/api/permission/user`;
  public static GetUserProfile = (userId: string) => `${baseUrl}/api/user/profile/${userId}`;
  public static ValidateUserToken = () => `${baseUrl}/api/user/validatetoken`;
  public static AcceptTermsOfUse: string = `${baseUrl}/api/user/acceptedtou`;
  public static GetUserAccounts = (userId: string) => `${baseUrl}/api/user/${userId}`;
  public static SwitchUserAccount = (userId: string, unitUid: string) =>
    `${baseUrl}/api/user/${userId}/switchaccount/${unitUid}`;
  public static ReleaseNotesSeen: string = `${baseUrl}/api/user/releasenotesseen`;
  public static FetchCustomerCodes = (globalId: string) =>
    `${baseUrl}/api/user/${globalId}/customercode`;
  public static CheckLoginType = (username: string) =>
    `${baseUrl}/api/logintype?username=${username}`;

  public static async handleGetUserUnit({ id }: { id: string }) {
    const resp = await apim.getB2BUnit('satairb2b', id, {
      asm: Cookies.get('asm') === 'true',
    });

    const b2bUnitWithMapping = {
      ...resp.data,
      addresses: [],
      companies: [],
      readOnlyAccount: false,
      exportBlocked: false,
      eusRequired: false,
      requiresCashInAdvance: false,
      forwarderInformationRequired: false,
      redB2BUnit: getB2bUnitMapping(resp.data.redB2BUnit || {}),
      blueB2BUnit: getB2bUnitMapping(resp.data.blueB2BUnit || {}),
      blueAddresses: [],
      redAddresses: [],
      payerAddressIsSameAsBilling: false,
    };

    return toLegacyResponse(resp, {
      Data: objectKeysToPascalCaseRecursive(b2bUnitWithMapping, [
        'b2BUnitSalesOrganization',
        'customerIdentificationCode',
      ]),
    });
  }
}
