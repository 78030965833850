<template>
  <div>
    <div class="product-card-label product-card-label--secondary" v-if="biddingActive">
      {{ textK('UI_SEARCHRESULT_OPENFORBIDS') }}
    </div>
    <div
      class="product-card-label product-card-label--interchangeable product-card-label--warning"
      v-if="hasPartNumberInterchangeableMatch && !isMarketplace"
    >
      <div>
        <svgicon name="ui-info"></svgicon>
      </div>
      <div
        v-html="partNumberInterchangeableMatchText(product.InterchangeableMatchedPartNumber)"
      ></div>
    </div>
    <div
      class="product-card-label product-card-label--interchangeables product-card-label--primary"
      v-if="hasOneWayInterchangeables && !isInterchangeableItem && !isMarketplace"
      @click="itemClick('interchangeables')"
    >
      {{ textK('UI_INTERCHANGEABLES_HAS_INTERCHANGEABLES') }}
    </div>
    <div
      class="product-card-label product-card-label--interchangeables product-card-label--primary"
      v-if="isLeaderPart"
      @click="itemClick('interchangeables')"
    >
      {{ textK('UI_INTERCHANGEABLES_LEADER_PART') }}
    </div>
  </div>
</template>

<style lang="scss" src="./product-card-label.scss" scoped></style>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { useUIStore } from '@/src/core/stores/ui';
import { OwningSystem, Product } from '@/src/core/types/api';
import { IProductAdditionalData } from '@/src/core/types/interfaces';
import { useRouter } from '@/src/core/utils/router';
import { productAdditionalData as productAdditionalDataUtil } from '@/src/market/services/product-parts';
import { useProductRelationsStore } from '@/src/market/stores/product-relations';
import { computed } from 'vue';

interface Props {
  product: Product;
  isInterchangeableItem: boolean;
  isLeaderPart: boolean;
  isFromPDP: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isInterchangeableItem: false,
  isLeaderPart: false,
  isFromPDP: false,
});
const textK = useText();
const productRelationsStore = useProductRelationsStore();
const authenticationStore = useAuthenticationStore();
const uiStore = useUIStore();
const modalStore = useModalStore();
const router = useRouter();
const product = computed(() => props.product);
const isInterchangeableItem = computed(() => props.isInterchangeableItem);
const isLeaderPart = computed(() => props.isLeaderPart);
const isFromPDP = computed(() => props.isFromPDP);

const isAirbus = computed(() => {
  return product.value?.OwningSystem === OwningSystem.BLUE;
});

const biddingActive = computed(() => {
  if (isAirbus.value) {
    return false;
  }

  return (
    product.value.IsMarketplace &&
    product.value.AllowQuoteRequests &&
    !product.value.PriceHidden &&
    !(
      (productAdditionalData.value as IProductAdditionalData)?.ProductRule.Quotable ===
      'OFFER_INACTIVE'
    )
  );
});

const productAdditionalData = computed(() => {
  return productAdditionalDataUtil(product.value.Id, product.value.OwningSystem);
});

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

const hasPartNumberInterchangeableMatch = computed(() => {
  return (
    product.value?.OwningSystem !== OwningSystem.BLUE &&
    product.value.PartNumberInterchangeableMatch
  );
});

const interchangeables = computed(() => {
  if (productAdditionalData.value && 'interchangeables' in productAdditionalData.value) {
    return productAdditionalData.value?.interchangeables;
  }

  return null;
});

const hasOneWayInterchangeables = computed(() => {
  if (isAirbus.value) {
    return isAuthenticated.value && interchangeables.value && interchangeables.value.length > 0;
  }
  return isAuthenticated.value && productRelationsStore.hasInterchangeables(product.value.Sku);
});

const partNumberInterchangeableMatchText = (partNumber?: string) => {
  return textK('UI_PRODUCT_PARTNUMBERINTERCHANGEABLETEXT')?.replace?.(
    '[[partnumber]]',
    `<span class="digits">${partNumber}</span>`,
  );
};

const isMarketplace = computed(() => {
  return product.value.IsMarketplace;
});

const itemClick = (scrollToEle: string | object) => {
  if (!product.value || product.value.IsUnknownProduct || product.value.LimitedByCageCode) {
    return;
  }

  if (modalStore.isVisible || uiStore.dropdownIsOpen) {
    return;
  }

  const routerOptions = {
    path: product.value.MarketOfferUrl,
  };

  if (typeof scrollToEle === 'string') {
    const key = 'query';

    if (isFromPDP.value) {
      routerOptions[key] = {
        fromPDP: true,
      };
    } else {
      routerOptions[key] = {
        scrollTo: scrollToEle,
      };
    }
  }

  router.push(routerOptions);
};
</script>
