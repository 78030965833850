import accountRoutes from '@/src/account/router';
import contentRoutes from '@/src/content/router';
import AuthOrLogin from '@/src/core/router/auth-or-login/auth-or-login';
import DetectSiteChange from '@/src/core/router/detectSiteChange';
import RouteAuthenticationGate from '@/src/core/router/route-authentication-gate/route-authentication-gate';
import UiAnimationGate from '@/src/core/router/ui-animation/ui-animation';
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { useRouteConfigStore } from '@/src/core/stores/route-config';
import { RouteConfigMeta } from '@/src/core/types/interfaces';
import { adobeTargetTriggerView } from '@/src/core/utils/adobe-target';
import financeRoutes from '@/src/finance/router';
import marketRoutes from '@/src/market/router';
import oidcRoutes from '@/src/oidc/router';
import planningRoutes from '@/src/planning/router';
import profileRoutes from '@/src/profile/router';
import debounce from 'lodash/debounce';
import Vue from 'vue';
import VueRouter, { Route, RouteConfig, RouteMeta, START_LOCATION } from 'vue-router';
import { useMaintenanceStore } from '../stores/maintenance';

const routes: RouteConfig[] = [
  ...marketRoutes,
  ...financeRoutes,
  ...profileRoutes,
  ...accountRoutes,
  ...planningRoutes,
  ...oidcRoutes,
  // contentRoutes need to be last, because of the catch-all route
  ...contentRoutes,
];

const maintenanceStore = useMaintenanceStore();

const router = new VueRouter({
  routes,
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior: (to, from) => {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' };
    }

    // Don't scroll to top on the brand site
    if (to.name === 'mainUmbracoContent') {
      return;
    }

    // Don't scroll to top when route only change queries
    if (from.path === to.path) {
      return;
    }

    //This will prevent the orders tab to scroll to top when closing the detail page
    if (from.name === 'orders' && to.name === 'ordersSale') {
      return;
    }

    // Dpn't scroll to top when the last route is on detail page
    if (!from.meta?.isDetailPage) {
      return { x: 0, y: 0, behavior: 'smooth' };
    }

    return;
  },
});

router.beforeEach(async (to, from, next) => {
  const routeConfigStore = useRouteConfigStore();

  maintenanceStore.fetchMaintenanceModeStatus();

  if (!routeConfigStore.isFetched) {
    await routeConfigStore.fetchRouteConfig();
  }

  let meta: RouteConfigMeta | RouteMeta | null = null;
  const metaFromRouteConfig = routeConfigStore.getMenuConfig(to?.name || '', 'meta');

  if (to.name && metaFromRouteConfig) {
    // Umbraco config
    meta = metaFromRouteConfig;
  } else if (to.name && to.meta) {
    // router meta (eg, from 'market' router.ts )
    meta = to.meta;

    if (to.meta.title) {
      document.title = to.meta.title;
    }
  }

  if (meta && meta.title) {
    document.title = meta.title;
  }

  // NOTES:
  // If not loginRestricted, then allow all
  // Else check Auth, beta restriction or access restriction
  if (!to.meta?.loginRestricted) {
    DetectSiteChange(to, from, next);
  } else {
    AuthOrLogin({
      cb: () => DetectSiteChange(to, from, next),
      betaRestricted: to.meta.betaRestricted,
      allowAccess: Object.prototype.hasOwnProperty.call(to.meta, 'accessRestricted')
        ? to.meta.accessRestricted
        : undefined,
    });

    next();
  }

  Vue.nextTick(() => {
    RouteAuthenticationGate();
    UiAnimationGate();
  });
});

router.afterEach((to: Route, from: Route) => {
  if (
    maintenanceStore.maintenanceMode &&
    to.matched[0].name === 'market' &&
    to.name !== 'serviceunavailable'
  ) {
    router.push({ name: 'serviceunavailable' });
  }
  const path: string = to.fullPath;
  adobeTargetTriggerView(path.length > 0 ? path : 'frontpage', { page: true });
  const debounceFunction = debounce(
    (reload = false) => AdobeLaunchTracking.pageView(to, from, to.meta!, reload),
    1000,
  );

  if (from === START_LOCATION) {
    debounceFunction(true);
  } else {
    if (to.name !== from.name) {
      AdobeLaunchTracking.pageView(to, from, to.meta!);
    }
  }
});

export default router;
