const MarketModals = {
  ModalSearchFilter: () => import('./modal-search-filter/modal-search-filter.vue'),
  ModalRequestPartLease: () =>
    import('@/src/market/components/modals/modal-request-part-lease/modal-request-part-lease.vue'),
  ModalAirbusRequestQuoteAddress: () =>
    import(
      '@/src/market/components/modals/modal-airbus-request-quote-address/modal-airbus-request-quote-address.vue'
    ),
  ModalExportLeaseOrders: () =>
    import(
      '@/src/market/components/modals/modal-export-lease-orders/modal-export-lease-orders.vue'
    ),
  ModalExportRepairExchangeOrders: () =>
    import(
      '@/src/market/components/modals/modal-export-repair-exchange-orders/modal-export-repair-exchange-orders.vue'
    ),
  ModalRequestToolUsed: () =>
    import('@/src/market/components/modals/modal-request-tool-used/modal-request-tool-used.vue'),
  ModalQuoteEmailNotification: () =>
    import(
      '@/src/market/components/modals/modal-quote-email-notification/modal-quote-email-notification.vue'
    ),
  ModalCreateAirbusQuote: () =>
    import(
      '@/src/market/components/modals/modal-create-airbus-quote/modal-create-airbus-quote.vue'
    ),
  ModalAirbusProprietaryPart: () =>
    import('./modal-airbus-proprietary-part/modal-airbus-proprietary-part.vue'),
  ModalAddToList: () => import('./modal-add-to-list/modal-add-to-list.vue'),
  ModalCheckoutSelectAddress: () =>
    import('./modal-checkout-select-address/modal-checkout-select-address.vue'),
  ModalCheckoutSelectForwarder: () =>
    import('./modal-checkout-select-forwarder/modal-checkout-select-forwarder.vue'),
  ModalClearCart: () => import('./modal-clear-cart/modal-clear-cart.vue'),
  ModalCreateList: () => import('./modal-create-list/modal-create-cart.vue'),
  ModalDeleteList: () => import('./modal-delete-cart/modal-delete-cart.vue'),
  ModalDownloadDocuments: () => import('./modal-download-documents/modal-download-documents.vue'),
  ModalRenameList: () => import('./modal-rename-list/modal-rename-list.vue'),
  ModalSaveAsList: () => import('./modal-save-as-list/modal-save-cart.vue'),
  ModalCartChanged: () => import('./modal-cart-changed/ModalCartChanged.vue'),
  ModalSelectWarehouse: () => import('./modal-select-warehouse/modal-select-warehouse.vue'),
  ModalConfirmEntryDelete: () =>
    import('./modal-confirm-entry-delete/modal-confirm-entry-delete.vue'),
  ModalCartCurrency: () => import('./modal-cart-currency/ModalCartCurrency.vue'),
  ModalSellerConversation: () =>
    import('./modal-seller-conversation/modal-seller-conversation.vue'),
  ModalImage: () => import('./modal-image/modal-image.vue'),
  ModalRequestQuote: () => import('./modal-request-quote/modal-request-quote.vue'),
  ModalRequestQuoteSelectAddress: () =>
    import('./modal-request-quote-select-address/modal-request-quote-select-address.vue'),
  ModalSellerQuoteConversation: () =>
    import('./modal-seller-quote-conversation/modal-seller-quote-conversation.vue'),
  ModalCreateQuote: () => import('./modal-create-quote/modal-create-quote.vue'),
  ModalCreateQuoteSelectAddress: () =>
    import('./modal-create-quote-select-address/modal-create-quote-select-address.vue'),
  ModalCreateSingleLineQuote: () =>
    import('./modal-create-single-line-quote/modal-create-single-line-quote.vue'),
  ModalForwarderInformation: () =>
    import('./modal-forwarder-information/modal-forwarder-information.vue'),
  ModalCreateQuoteList: () => import('./modal-create-quote-list/modal-create-quote-list.vue'),
  ModalMinimOrderValue: () => import('./modal-minim-order-value/modal-minim-order-value.vue'),
  ModalOrdersFilter: () => import('./modal-orders-filter/modal-orders-filter.vue'),
  ModalMaterialStatus: () => import('./modal-material-status/modal-material-status.vue'),
  ModalUpdateRemark: () => import('./modal-update-remark/modal-update-remark.vue'),
  ModalRemark: () => import('./modal-remark/modal-remark.vue'),
  ModalBoxDetails: () => import('./modal-box-details/modal-box-details.vue'),
  ModalLeasePrice: () => import('./modal-lease-price/modal-lease-price.vue'),
  ModalHeavyMaintenance: () =>
    import('./modal-heavy-maintenance-form/modal-heavy-maintenance-form.vue'),
  ModalRequestExchange: () => import('./modal-request-exchange/modal-request-exchange.vue'),
  ModalRequestToolLease: () => import('./modal-request-tool-lease/modal-request-tool-lease.vue'),
  ModalAirbusRepairRequestQuote: () =>
    import('./modal-airbus-repair-request-quote/modal-airbus-repair-request-quote.vue'),
  ModalRequestQuoteFHS: () => import('./modal-request-quote-fhs/modal-request-quote-fhs.vue'),
};

export default MarketModals;
