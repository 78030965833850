import AssistantContainer from '@/src/core/components/assistant/assistant-container/assistant-container.vue';
import Modal from '@/src/core/components/modals/modal.vue';
import GlobalNotification from '@/src/core/components/ui/global-notification/global-notification.vue';
import Notification from '@/src/core/components/ui/notification/notification.vue';
import SplashScreen from '@/src/core/components/ui/splash-screen/splash-screen.vue';
import svgicon from '@/src/core/components/ui/svgicon/svgicon.vue';
import AdobeTargetEventHandler from '@/src/core/components/util/adobe-target-event-handler/adobe-target-event-handler.vue';
import AsmChecker from '@/src/core/components/util/asm-checker/asm-checker.vue';
import '@/src/core/components/util/click-outside';
import ContentOverlay from '@/src/core/components/util/content-overlay/content-overlay.vue';
import InactivityTimer from '@/src/core/components/util/inactivity-timer/inactivity-timer.vue';
import LockScroll from '@/src/core/components/util/lock-scroll/lock-scroll.vue';
import SessionTimer from '@/src/core/components/util/session-timer/session-timer.vue';
import UiTests from '@/src/core/plugins/ui-test-tags';
import router from '@/src/core/router';
import '@/src/core/services/environment';
import { getEnv } from '@/src/core/services/environment';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { BreakpointWidth } from '@/src/core/settings/media-queries.model';
import { useGlobalNotificationStore } from '@/src/core/stores/global-notification';
import { IWindowServerRendered } from '@/src/core/types/ui';
import { pinia } from '@/src/core/utils/pinia-utils';
import { ResizeEvent, ResizeEventBus, ResizeEventSizes } from '@/src/core/utils/resize-event-bus';
import { ScrollEvent, ScrollEventBus } from '@/src/core/utils/scroll-event-bus';
import { WhatInputHandler } from '@/src/core/utils/what-input-handler';
import template from '@/src/main.vue';
import '@/src/style.scss';
import { PiniaVuePlugin } from 'pinia';
import PortalVue from 'portal-vue';
import 'scrollmonitor';
import vClickOutside from 'v-click-outside';
import Vue from 'vue';
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import { Component, Watch } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import { useAuthenticationStore } from './core/stores/authentication';
import { useLinksStore } from './core/stores/links';
import { useUIStore } from './core/stores/ui';

// PINIA
Vue.use(PiniaVuePlugin);

Vue.use(VueRouter);

Vue.use(vClickOutside);

// SVG ICON
Vue.component('Svgicon', svgicon);

// DATEPICKER
Vue.use(AirbnbStyleDatepicker, {
  sundayFirst: true,
});

// VUE DATA-ATTR FOR UI-TESTS
Vue.use(UiTests, {
  showTestAttr: getEnv('VUE_APP_UITEST'),
});

// PORTAL VUE, for moving component to places...
Vue.use(PortalVue);

// WhatInput global listener
WhatInputHandler.init();

@Component({
  pinia,
  mixins: [template],
  components: {
    GlobalNotification,
    Notification,
    Modal,
    AssistantContainer,
    ContentOverlay,
    SplashScreen,
    SessionTimer,
    InactivityTimer,
    AsmChecker,
    AdobeTargetEventHandler,
    LockScroll,
  },
  directives: {},
  router,
})
class App extends Vue {
  public authenticationStore = useAuthenticationStore();
  public globalNotificationStore = useGlobalNotificationStore();
  public linksStore = useLinksStore();
  public uiStore = useUIStore();
  public breakpointWidth = BreakpointWidth;
  private disableUIAnimations: boolean = false;
  private enableHelpers: boolean = false;

  private get subContentOpen() {
    return this.uiStore.subContentOpen;
  }

  private get globalNotification() {
    return this.globalNotificationStore.notification.Body;
  }

  public get inactivityTimerEnabled() {
    return hasFeature(FEATURES.INACTIVITY_LOGOUT_TIMER);
  }

  @Watch('$route') private routeChange() {
    if (this.uiStore.navigationDropdownId) {
      this.uiStore.setNavigationDropdownId({ id: null });
    }
  }

  public beforeCreate() {
    const srProduct: NodeListOf<Element> = document.querySelectorAll('[sr-product]');
    const srContent: NodeListOf<Element> = document.querySelectorAll('[sr-content]');
    const srSeller: NodeListOf<Element> = document.querySelectorAll('[sr-seller]');
    const srData: NodeListOf<Element> = document.querySelectorAll('[sr-data]');

    if (srProduct.length > 0) {
      (window as unknown as IWindowServerRendered).srProduct = srProduct[0] as HTMLElement;
    }

    if (srSeller.length > 0) {
      (window as unknown as IWindowServerRendered).srSeller = srSeller[0] as HTMLElement;
    }

    if (srContent.length > 0) {
      const srSection: NodeListOf<Element> = srContent[0].querySelectorAll('[sr-section]');

      (window as unknown as IWindowServerRendered).srContent =
        (srSection.length > 0 && (srSection[0] as HTMLElement)) || (srContent[0] as HTMLElement);
    }

    if (srData.length > 0) {
      const dataString: string | null = srData[0].getAttribute('sr-data');
      (window as unknown as IWindowServerRendered).srData = dataString && JSON.parse(dataString);
    }
  }

  public created() {
    if (this.authenticationStore.hasStorageToken) {
      this.authenticationStore.refreshLogin();
    }

    if (getEnv('VUE_APP_UITEST') && window.URLSearchParams) {
      const urlParam = new URLSearchParams(window.location.search);
      const uiTestQuery = urlParam.get('ui-test');
      this.enableHelpers = urlParam.get('enable-helpers') !== null;
      if (uiTestQuery) {
        this.uiStore.setDisableUIAnimations({ disable: true });
        this.disableUIAnimations = true;
      }
    }
  }

  public onResize($event: Event) {
    const windowSize: ResizeEventSizes = {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
    ResizeEventBus.$emit('resize', { windowSize, originalEvent: $event } as unknown as ResizeEvent);
  }

  public onScroll($event: Event) {
    const scrollPosition: number = window.scrollY || window.pageYOffset;
    ScrollEventBus.$emit('scroll', { scrollPosition, originalEvent: $event } as ScrollEvent);
  }

  public mounted() {
    this.linksStore.fetchLinks();
    window.addEventListener('scroll', ($event: Event) => this.onScroll($event));
    window.addEventListener('resize', ($event: Event) => this.onResize($event));
  }

  // Get ASM info
  // 'ASM Active' will set a HTML class
  protected get asmInfo() {
    return this.authenticationStore.asm;
  }

  public get isAuthenticated(): boolean {
    return this.authenticationStore.isAuthenticated;
  }
}

export const app = new App().$mount('#app');
