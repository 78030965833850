<template>
  <div class="search-item">
    <!-- Product Card Label -->
    <ProductCardLabel
      :is-interchangeable-item="isInterchangeableItem"
      :product="product"
      :is-leader-part="isLeaderPart"
      :is-from-p-d-p="isFromPDP"
    ></ProductCardLabel>

    <div class="search-item__wrapper" v-ui-test="'search-item-wrapper'">
      <div
        class="search-item__border-left"
        :class="{
          'search-item__border-left--active': isActive,
        }"
      ></div>

      <div
        class="search-item__card"
        @mouseover="
          () => {
            isHovered = true;
            isActive = true;
          }
        "
        @mouseout="isHovered = false"
        @mousemove="hoverNext = true"
        :class="{
          'search-item__card--active': isActive,
          'search-item__card--unauthorized': !isAuthenticated,
        }"
      >
        <div class="search-item__top">
          <div class="search-item__top-info">
            <span class="search-item__title">
              <Tooltip v-if="hasPartNumberInterchangeableMatch">
                <OnewayInterchangeable />
                <template #tooltip-content>
                  <div class="tooltip-notice">
                    {{ textK('UI_INTERCHANGEABLES_ONEWAY_INTERCHANGEABLES') }}
                  </div>
                </template>
              </Tooltip>
              <Tooltip v-else-if="isInterchangeableItem">
                <TwowayInterchangeable v-if="isTwoWay" />
                <OnewayInterchangeable v-else />
                <template #tooltip-content>
                  <div class="tooltip-notice">
                    {{
                      isTwoWay
                        ? textK('UI_INTERCHANGEABLES_TWOWAY_INTERCHANGEABLES')
                        : textK('UI_INTERCHANGEABLES_ONEWAY_INTERCHANGEABLES')
                    }}
                  </div>
                </template>
              </Tooltip>
              <span class="search-item__manufacturer-aid">{{ product.ManufacturerAid }}:</span>
              <span class="search-item__manufacturer-code">
                {{ product.ProductManufacturer.CageCode }}
                <CopyButton
                  class="search-item__copy-button"
                  :class="{ 'search-item__copy-button--active': isActive || isTouch }"
                  :title="textK('UI_PRODUCT_COPY_PNR')"
                  :copy-string="product.ManufacturerAid"
                />
              </span>
            </span>

            <div class="search-item__info">
              <p class="search-item__name">
                <span>{{ product.Name && product.Name.length > 0 ? product.Name : '-' }} </span>
              </p>

              <p class="search-item__manufacturer">
                {{ textK('UI_PRODUCT_MANUFACTURERCODE') }}
                <span :title="titleTag(product.ProductManufacturer.Name)">{{
                  product.ProductManufacturer.Name ? product.ProductManufacturer.Name : '-'
                }}</span>
                <span :title="titleTag(product.ProductManufacturer.CageCode)"
                  >({{ product.ProductManufacturer.CageCode }})</span
                >
              </p>
              <ProductClassificationIcons
                class="search-item__classification-icons"
                :product="product"
                :procurement="procurement"
              />
            </div>
          </div>

          <div
            class="search-item__company-logo"
            :class="{
              'company-logo--has-image': product.ShowSupplierLogo,
            }"
          >
            <ProductCompanyLogo :logo="masterShopLogo" />
          </div>
        </div>

        <!-- Product Tabs -->
        <ProductTabs
          :active-tab.sync="procurementType"
          :product="product"
          :is-active="isActive"
          @activeTab="getTab($event)"
          @itemSelect="itemClick($event)"
          :is-interchangeable-item="isInterchangeableItem"
          :is-fhs="isFhs"
        />
        <!-- / Product Tabs -->

        <!-- ContextualMenu -->
        <ContextualActionsContainer
          v-if="isAuthenticated"
          class="search-item__contextual-actions"
          :class="{ 'search-item__contextual-actions--isTouch': isTouch }"
          :is-hovered="isHovered"
        >
          <template #default="scope">
            <ContextualActions @open="scope.setIsOpen($event)" :is-product-card="true">
              <DropdownOption
                @click.stop="
                  openModal({
                    modalComponent: 'ModalAddToList',
                    params: { Code: product.Id, ActionView: 'search result' },
                  })
                "
                :disabled="isDropdownDisabled || withProcurementsNotSellable"
                v-ui-test="textK('UI_PRODUCT_ADDTOLIST')"
              >
                {{ textK('UI_PRODUCT_ADDTOLIST') }}
              </DropdownOption>
              <DropdownOption
                v-if="isAuthenticated && product && product.IsMarketplace"
                @click.stop="
                  openModal({
                    modalComponent: 'ModalSellerConversation',
                    params: { id: product.Id },
                  })
                "
                :disabled="isAskSellerDisabled"
                v-ui-test="'ask-seller'"
              >
                {{ textK('UI_SELLER_CONVERSATION_CTA') }}
              </DropdownOption>
            </ContextualActions>
          </template>
        </ContextualActionsContainer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./search-item.scss" scoped></style>

<script lang="ts" setup>
import ContextualActionsContainer from '@/src/core/components/ui/contextual-actions-container/contextual-actions-container.vue';
import ContextualActions from '@/src/core/components/ui/contextual-actions/contextual-actions.vue';
import CopyButton from '@/src/core/components/ui/copy-button/copy-button.vue';
import DropdownOption from '@/src/core/components/ui/dropdown/dropdown-option/dropdown-option.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import useTitleTag from '@/src/core/hooks/useTitleTag';
import { useAssistantStore } from '@/src/core/stores/assistant';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { useUIStore } from '@/src/core/stores/ui';
import { OwningSystem, Product } from '@/src/core/types/api';
import { AirbusPart, IProductAdditionalData } from '@/src/core/types/interfaces';
import { IeKeys } from '@/src/core/types/keys';
import { RuleStatus } from '@/src/core/types/rule-status';
import { useRoute, useRouter } from '@/src/core/utils/router';
import { WhatInputBus } from '@/src/core/utils/what-input-event-bus';
import { WhatInputHandler } from '@/src/core/utils/what-input-handler';
import ProductCardLabel from '@/src/market/components/search/search-item/components/product-card-label/product-card-label.vue';
import ProductClassificationIcons from '@/src/market/components/search/search-item/components/product-classification-icons/product-classification-icons.vue';
import ProductCompanyLogo from '@/src/market/components/search/search-item/components/product-company-logo/product-company-logo.vue';
import ProductTabs from '@/src/market/components/search/search-item/components/product-tabs/product-tabs.vue';
import OnewayInterchangeable from '@/src/market/components/ui/oneway-interchangeable/oneway-interchangeable.vue';
import TwowayInterchangeable from '@/src/market/components/ui/twoway-interchangeable/twoway-interchangeable.vue';
import {
  isAirbus,
  isSatair,
  productAdditionalData as productAdditionalDataService,
} from '@/src/market/services/product-parts';
import { hasNoPrice, isPriceVisible } from '@/src/market/services/product-rules';
import { useAirbusPartStore } from '@/src/market/stores/airbus-part';
import { useProductFHSDetailsStore } from '@/src/market/stores/product-fhs-details';
import { useSearchStore } from '@/src/market/stores/search';
import { Key } from 'ts-key-enum';
import { computed, defineProps, onMounted, onUnmounted, ref, watch } from 'vue';

interface Props {
  product: Product;
  index?: number;
  isInterchangeableItem?: boolean;
  isLeaderPart?: boolean;
  isTwoWay?: boolean;
  isFromPDP?: boolean;
  isFhs?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  index: -1,
  isInterchangeableItem: false,
  isLeaderPart: false,
  isTwoWay: false,
  isFromPDP: false,
  isFhs: false,
});

const textK = useText();
const openModal = useModal();
const titleTag = useTitleTag;
const searchStore = useSearchStore();
const airbusPartsStore = useAirbusPartStore();
const fhsDetailsStore = useProductFHSDetailsStore();
const authenticationStore = useAuthenticationStore();
const assistantStore = useAssistantStore();
const uiStore = useUIStore();
const modalStore = useModalStore();
const router = useRouter();
const route = useRoute();

const isTouch = ref(false);
const hoverNext = ref(true);
const isHovered = ref(false);
const isNotSellable = ref(false);

const procurementType = ref('');

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const isActive = computed({
  get() {
    hoverNext.value = false;
    return props.index === -1
      ? isHovered.value
      : props.index === searchStore.activeProductIndex || false;
  },
  set(value) {
    return value;
  },
});

const readOnlyAccount = computed(() => authenticationStore.readOnlyAccount);

const isBlueInterchangable = computed(
  () => props.isInterchangeableItem && props.product.OwningSystem === OwningSystem.BLUE,
);

const productAdditionalData = computed(() => {
  if (!props.product) return null;

  return isBlueInterchangable.value
    ? airbusPartsStore.products.find(({ productId }) => productId === props.product.Id)
    : productAdditionalDataService(props.product.Id, props.product.OwningSystem);
});

const procurement = computed(() => {
  if (isAirbus(productAdditionalData.value)) {
    return productAdditionalData.value.procurements.find(
      (proc) => proc.procurementType === procurementType.value,
    );
  }
  return undefined;
});

const ruleStatusOk = computed(() => isPriceVisible(productAdditionalData.value, procurement.value));
const ruleStatusNoPrice = computed(() =>
  hasNoPrice(productAdditionalData.value, procurement.value),
);
const isAsmHidden = computed(() =>
  isSatair(productAdditionalData.value) ? productAdditionalData.value.BasePrice.IsAsmHidden : false,
);
const unknownDeliveryDate = computed(() =>
  isSatair(productAdditionalData.value) ? productAdditionalData.value.UnknownDeliveryDate : false,
);
const hasPartNumberInterchangeableMatch = computed(
  () =>
    props.product?.OwningSystem !== OwningSystem.BLUE &&
    props.product.PartNumberInterchangeableMatch,
);
const isAirbusProduct = computed(() => isAirbus(productAdditionalData.value));
const withProcurementsNotSellable = computed(() => isNotSellable.value);

const isDropdownDisabled = computed(() => {
  if (isAirbusProduct.value) {
    return (
      !productAdditionalData.value ||
      (productAdditionalData.value as AirbusPart).productRule.sellable !== RuleStatus.YES
    );
  }
  return (
    !productAdditionalData.value ||
    ![RuleStatus.OK, RuleStatus.NO_PRICE, RuleStatus.RESTRICTED].includes(
      (productAdditionalData.value as IProductAdditionalData).RuleStatus as RuleStatus,
    )
  );
});

const isAskSellerDisabled = computed(() => {
  if (!productAdditionalData.value) {
    return true;
  }

  if (
    productAdditionalData.value &&
    ((!ruleStatusOk.value && !ruleStatusNoPrice.value) ||
      isAsmHidden.value ||
      unknownDeliveryDate.value ||
      readOnlyAccount.value)
  ) {
    return true;
  }

  return false;
});

const fhsMasterShop = computed(() => fhsDetailsStore.getMasterShopByProductId(props.product.Id));

const masterShopLogo = computed(() => {
  if (props.isFhs && isAuthenticated.value) {
    const shop = fhsMasterShop.value;
    const url = shop?.newSearchResultLogo ?? '';
    const name = shop?.name ?? '';
    return { url, name };
  } else {
    const url =
      props.product.NewSupplierLogoCdnUrl ??
      props.product.NewSupplierLogoUrl ??
      props.product.SupplierLogo;
    const name = props.product.SupplierDisplay;

    return { url, name };
  }
});

const itemClick = (
  scrollToEle?: string | { procurementType: string; serialNumber: string | undefined },
) => {
  if (!props.product) return;
  if (props.isFromPDP) uiStore.setClickFromPDP(props.isFromPDP);

  let routerOptions = {
    path: props.product.MarketOfferUrl,
  };

  if (typeof scrollToEle === 'object') {
    const { procurementType, serialNumber } = scrollToEle;
    const formattedProcurement = procurementType.toLowerCase();
    let path = props.product.MarketOfferUrl;

    if (serialNumber) {
      const formattedSerial = encodeURIComponent(serialNumber).toLowerCase();
      path = `${props.product.MarketOfferUrl}/${formattedProcurement}/${formattedSerial}`;
    } else {
      path = `${props.product.MarketOfferUrl}/${formattedProcurement}`;
    }

    routerOptions = { ...routerOptions, path };
  }

  if (typeof scrollToEle === 'string') {
    const key = 'query';
    routerOptions[key] = {
      scrollTo: scrollToEle,
    };
  }

  router.push(routerOptions);
};

const onKeyPressed = ($event: KeyboardEvent) => {
  const key: string = $event.key;

  if (route.name !== 'search' || assistantStore.assistantActive || modalStore.isVisible) {
    return;
  }

  switch (key) {
    case Key.Enter:
      $event.preventDefault();
      itemClick();
      break;

    case Key.Escape:
    case IeKeys.Escape:
      $event.preventDefault();
      break;
  }
};

const inputTypeChanged = (type: string) => {
  isTouch.value = type === 'touch';
};

const getTab = (type: { isDisabled: boolean }) => {
  if (type) isNotSellable.value = type.isDisabled;
};

watch(
  () => isActive.value,
  (newValue, oldValue) => {
    if (newValue && !oldValue) {
      window.addEventListener('keydown', onKeyPressed);
    } else if (!newValue && oldValue) {
      window.removeEventListener('keydown', onKeyPressed);
    }

    if (props.index > -1 && newValue && hoverNext.value) {
      searchStore.setActiveProductIndex({ index: props.index });
    }
  },
);

onMounted(() => {
  isTouch.value = WhatInputHandler.hasTouch();
  WhatInputBus.$on('inputTypeChanged', inputTypeChanged);
});

onUnmounted(() => {
  WhatInputBus.$off('inputTypeChanged', inputTypeChanged);
  window.removeEventListener('keydown', onKeyPressed);
});
</script>
