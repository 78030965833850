import { OrderDocumentInfo } from '@/src/core/apim';
import { isDefined } from '@/src/core/utils/object';
import { OrderDetailsMapping } from '@/src/market/services/legacy-mapping-order-details';

const groupNaming: Record<string, string> = {
  P: 'Invoice', // Debit memo
  O: 'Invoice', // Credit memo
  M: 'Invoice', // Invoice
  C: 'Order acknowledgement', // Standard order
  '8': 'Shipping documents', // Shipment
  X: 'Packing list', // Handling unit
  U: 'Proforma invoice', // Pro forma invoice
  XCR: 'Certificate',
  // Marketplace grouping
  PROFORMA_INVOICE: 'Proforma invoice', // Pro forma invoice
  CUSTOMER_INVOICE: 'Invoice',
  CERTIFICATE: 'Certificate',
  SHOP_REPORT: 'Shop report',
  ORDER_ACKNOWLEDGEMENT: 'Order acknowledgement',
  PACKING_LIST: 'Packing list',
  SHIPPING_DOCUMENTS: 'Shipping documents',
  TRACE: 'Trace',
  NON_INCIDENT_STATEMENT: 'Non incident statement',
  CONSOLIDATED_DOCUMENTS: 'Consolidated documents',
  SYSTEM_MESSAGE_ATTACHMENT: 'Chat attachments',
};

const groupOrdering: Record<string, number> = {
  'Order acknowledgement': 5,
  'Packing list': 10,
  'Shipping documents': 15,
  'Proforma invoice': 17,
  Invoice: 20,
  Certificate: 40,
  'Shop report': 43,
  Trace: 45,
  'Non incident statement': 47,
  'Consolidated documents': 50,
  'Chat attachments': 4,
};

export interface DocumentMapped {
  documentUrl: string;
  documentCategory: string;
  documentNumber?: string | undefined;
}

export const getDocumentsGrouped = (documents: OrderDocumentInfo[], order: OrderDetailsMapping) => {
  if (documents && documents.length > 0) {
    const mappedDocuments = documents
      .map((d) => {
        return d.documentCategory
          ? {
              ...(d.referenceToBoxNumber && { referenceToBoxNumber: d.referenceToBoxNumber }),
              description: formatDescription(d),
              documentUrl: `/api/order/${order.code}/download?id=${d.documentNumber}&cat=${d.documentCategory}`,
              documentCategory: groupNaming[d.documentCategory],
              documentNumber: getDocumentNumner(d),
            }
          : null;
      })
      .filter(isDefined)
      .filter(removeAirbusDocuments)
      .sort(sortingFunction);

    return groupBy(mappedDocuments, (d) => d.documentCategory);
  }

  if (order.consignments && order.consignments.some((c) => c.documents && c.documents.length > 0)) {
    const orderDocs = order.consignments.flatMap(
      (x) =>
        x.documents
          ?.map((y) => {
            const val = y.type && groupNaming[y.type];
            if (val) {
              return {
                consignmentCode: x.code,
                documentUrl: `/api/order/${order.code}/offerdownload?consignmentCode=${x.code}&documentId=${y.code}&fileName=${y.fileName}`,
                documentCategory: val,
                description: y.fileName,
              };
            }
            return null;
          })
          .filter(isDefined)
          .sort(sortingFunction),
    );

    return groupBy(orderDocs, (d) => d?.documentCategory);
  }

  return [];
};

function groupBy<T, K>(array: T[], keyGetter: (item: T) => K): T[][] {
  const map = new Map<K, T[]>();

  for (const item of array) {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  }

  return Array.from(map.values());
}

const removePreZero = (str?: string) => {
  if (typeof str === 'string') {
    return str.replace(/^0+/, '');
  }
  return str;
};

const getDocumentNumner = (document?: OrderDocumentInfo) => {
  return document?.referenceToBoxNumber
    ? removePreZero(document.referenceToBoxNumber)
    : removePreZero(document?.documentNumber);
};

const removeAirbusDocuments = (document?: OrderDocumentInfo | null) => {
  if (document == null) return false;
  const documentNumber = getDocumentNumner(document);
  return !documentNumber?.startsWith('3'); // 3 = Airbus
};

const formatDescription = (d?: OrderDocumentInfo) => {
  if (d?.description?.toLowerCase() === 'shipment') {
    return 'Shipment overview';
  }
  if (
    d?.description?.toLowerCase() === 'handling unit' &&
    d.documentCategory?.toLowerCase() !== 'certificate'
  ) {
    return 'Packing list - Certificate of Conformance';
  }
  if (d?.description?.toLowerCase() === 'proforma invoice') {
    return 'Proforma invoice';
  }
  if (d?.documentCategory?.toLowerCase() === 'certificate') {
    return 'Certificate';
  }
  return d?.description;
};

const sortingFunction = (a: DocumentMapped, b: DocumentMapped) => {
  const orderA = groupOrdering[a.documentCategory] || Number.MAX_SAFE_INTEGER;
  const orderB = groupOrdering[b.documentCategory] || Number.MAX_SAFE_INTEGER;
  return orderA - orderB;
};
