// tslint:disable
import { AirbusOrderDetails, apim, HttpResponse, OrderDocumentInfo } from '@/src/core/apim';
import Cookies from 'js-cookie';
import { getEnv } from '@/src/core/services/environment';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import {
  addOrderToCartData,
  CartModificationResponseData,
  getOrderSearchPageWithMapping,
} from '@/src/market/services/legacy-mapping-orders';
import { AxiosRequestConfig } from 'axios';
import { MASTERSHOP_ID } from '@/src/market/settings/mastershop';
import { SATAIR_BASESITE_ID } from '@/src/market/settings/apim';
import { NotificationTypes } from '@/src/core/types/api';
import { SellerApi } from '@/src/core/api';
import {
  ConsignmentThreadMapping,
  getConsignmentThreadList,
  getOrderDetailsData,
  getOrderDetailsMapping,
} from '@/src/market/services/legacy-mapping-order-details';
import { toLegacyDownloadResponse } from '@/src/market/services/legacy-download-response';
import { getMimeTypeExtension } from '@/src/market/services/mapping-extensions';
import { useOrderDetailsStore } from '@/src/market/stores/order-details';
import { getFileNameFromHeaders } from '@/src/market/services/legacy-mapping-filename-headers';

import { defaultError, ErrorModule } from '@/src/market/settings/error';
import {
  ErrorResponse,
  handleErrorResponse,
} from '@/src/market/services/error-notification-handling';
import { getNotificationMapping } from '@/src/core/services/notification-mapping';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class OrderApi {
  public static AllOrders = `${baseUrl}/api/order/show`;
  public static RecentOrders = `${baseUrl}/api/order/recent`;
  public static OrderInfo = (orderCode: string) => `${baseUrl}/api/order/${orderCode}`;
  public static PlaceOrder = `${baseUrl}/api/cart/order`;
  public static AddOrderToCart = (orderCode: string, cartCode: string) =>
    `${baseUrl}/api/order/addtocart/${orderCode}/${cartCode}`;
  public static DownloadAllDocuments = (orderCode: string) =>
    `${baseUrl}/api/order/${orderCode}/downloadall`;
  public static GetOrderLog = (orderCode: string) => `${baseUrl}/api/order/${orderCode}/log`;
  public static DownloadEUSDocument = (orderCode: string) =>
    `${baseUrl}/api/order/${orderCode}/eusdownload`;
  public static DownloadAirbusOrderInvoice = `${baseUrl}/api/order/invoices/airbusinvoicedocuments`;
  public static DownloadAirbusOrderCertificate = (boxNumber: string) =>
    `${baseUrl}/api/order/certificate/${boxNumber}/download`;
  public static DownloadCustomsInvoice = (orderCode: string) =>
    `${baseUrl}/api/customsinvoices/airbuscustomsinvoicedocument/order/${orderCode}`;
  public static RepairExchangeOrders = `${baseUrl}/api/airbus/service-orders/repair-exchange`;
  public static LeaseOrders = `${baseUrl}/api/airbus/service-orders/lease`;
  public static RepairExchangeOrderDetails = (orderNumber: string) =>
    `${baseUrl}/api/airbus/service-order/${orderNumber}`;
  public static LeaseOrderDetails = (orderNumber: string) =>
    `${baseUrl}/api/airbus/service-order/${orderNumber}?isLease=true`;
  public static ExportLeaseOrders = `${baseUrl}/api/airbus/service-orders/lease/export`;
  public static ExportRepairExchangeOrders = `${baseUrl}/api/airbus/service-orders/repair-exchange/export`;

  public static async HandleAllOrders({
    params,
    isRecentOrders = false,
  }: {
    params: { facets: string; size: number; fromDate: string; toDate: string; p: number };
    isRecentOrders: boolean;
  }) {
    const globalId = Cookies.get('globalId');
    const { facets, size, fromDate, toDate, p } = params;

    try {
      const ordersResponse = await apim.searchOrders1(SATAIR_BASESITE_ID, {
        fields: 'FULL',
        globalId,
        q: facets,
        pageSize: size ? size.toString() : undefined,
        currentPage: p ? p.toString() : undefined,
        fromDate,
        toDate,
      });

      const ordersWithAirbusDetails = await Promise.all(
        (ordersResponse.data.orders ?? []).map(async (order) => {
          if (!isRecentOrders && order?.airbusSapOrderId) {
            const res = await apim.getAirbusOrderBySapOrderId(
              SATAIR_BASESITE_ID,
              order.airbusSapOrderId,
              {},
            );

            if (res.ok) {
              const airbusExtenderOrdersData: AirbusOrderDetails = await res.json();
              return {
                ...order,
                AirbusExtendedOrderDetails: airbusExtenderOrdersData,
              };
            }
          }
          return order;
        }),
      );

      const orderSearchPageWithMapping = getOrderSearchPageWithMapping({
        ...ordersResponse.data,
        orders: ordersWithAirbusDetails,
      });

      return toLegacyResponse(ordersResponse, orderSearchPageWithMapping);
    } catch (error) {
      const errorMapping = handleErrorResponse(error);
      return toLegacyResponse(error, errorMapping);
    }
  }

  public static async HandleOrderDetails({ orderCode }: { orderCode: string }) {
    const globalId = Cookies.get('globalId')!;
    const asm = Boolean(Cookies.get('asm'));
    const error: ErrorModule = {};

    let documents: OrderDocumentInfo[] = [];
    let consignmentThreadsList: ConsignmentThreadMapping = [];
    let termsAndPolicies = null;
    let airbusExtendedOrderDetails: AirbusOrderDetails = {};

    try {
      const orderResponse = await apim.getOrderForUserByCode(
        SATAIR_BASESITE_ID,
        orderCode,
        globalId,
        {
          fields: 'FULL',
          globalId,
          asm,
        },
      );

      const { airbusSapOrderId, code, masterShop } = orderResponse.data ?? {};

      if (airbusSapOrderId) {
        const airbusExtendedDetailsResponse = await apim.getAirbusOrderBySapOrderId(
          SATAIR_BASESITE_ID,
          airbusSapOrderId,
          {
            asm,
            globalId,
          },
        );
        airbusExtendedOrderDetails = airbusExtendedDetailsResponse.data;
      }

      if (orderResponse.data && code && masterShop?.id?.toLowerCase() === MASTERSHOP_ID.SATAIR) {
        const globalId = Cookies.get('globalId')!;
        const documentsResponse = await apim.getDocumentInfo(SATAIR_BASESITE_ID, code, globalId, {
          fields: 'FULL',
          asm,
          globalId,
        });

        if (documentsResponse.ok && documentsResponse.data.length > 0) {
          documents = documentsResponse.data;
        }
      } else if (!orderResponse.data.masterShop?.id) {
        error.hasError = true;
        error.fatalError = false;
      }

      if (masterShop?.id && masterShop.id?.toLowerCase() !== MASTERSHOP_ID.SATAIR) {
        const res = await SellerApi.handleMastershopById({ id: masterShop.id });

        if (res.data.Data.TermsAndPolicies) {
          termsAndPolicies = res.data.Data.TermsAndPolicies;
        }
      }

      const mappedOrder = getOrderDetailsMapping(orderResponse.data);

      if (
        code &&
        masterShop?.id?.toLowerCase() !== MASTERSHOP_ID.SATAIR &&
        mappedOrder.consignments &&
        mappedOrder.consignments.length > 0
      ) {
        const threads = await Promise.all(
          mappedOrder.consignments.map(async ({ code: consignmentCode }) => {
            const res = await apim.getOrderThreads(
              SATAIR_BASESITE_ID,
              consignmentCode!,
              code,
              globalId,
              {},
            );
            return res.json();
          }),
        );

        consignmentThreadsList = getConsignmentThreadList(threads, mappedOrder);
      }

      const data = getOrderDetailsData({
        airbusExtendedOrderDetails,
        mappedOrder,
        consignmentThreadsList,
        masterShop,
        termsAndPolicies,
        documents,
      });

      return toLegacyResponse(orderResponse, {
        Data: data,
        ...(error.hasError && { error }),
      });
    } catch (error) {
      const errorMapping = handleErrorResponse(error);
      return toLegacyResponse(error, errorMapping);
    }
  }

  public static async HandleDownloadAllDocuments({ orderCode }: { orderCode: string }) {
    const asm = Boolean(Cookies.get('asm'));
    const globalId = Cookies.get('globalId') || '';

    try {
      const response = await apim.downloadAllOrderDocuments(
        SATAIR_BASESITE_ID,
        orderCode,
        globalId,
        {
          globalId,
          asm,
        },
      );
      const processedResponse = await toLegacyDownloadResponse(response);
      const { ContentType, FileContents } = processedResponse ?? {};
      const fileExtension = getMimeTypeExtension(ContentType);
      const FileDownloadName = `${orderCode}.${fileExtension}`;

      const Data = {
        FileContents,
        ContentType,
        FileDownloadName,
      };

      return toLegacyResponse(response, {
        Data,
      });
    } catch (error) {
      const notification = getNotificationMapping({
        title: 'Failed to download documents',
        description: `Failed to download order documents. Order ${orderCode}`,
        type: NotificationTypes.Error,
      });

      const result = {
        error: defaultError,
        notification: [notification],
      };

      return toLegacyResponse(error, objectKeysToPascalCaseRecursive(result));
    }
  }

  public static async HandleAddToCart({ cartCode }: { cartCode: string }) {
    const globalId = Cookies.get('globalId') || '';
    const ordersStore = useOrderDetailsStore();
    const currentOrder = ordersStore.order;
    const orderEntries = currentOrder.Entries.map((entry) => ({
      id: entry.Id,
      quantity: entry.Quantity || 1,
    }));

    try {
      const response = await apim.addCartEntries(
        SATAIR_BASESITE_ID,
        cartCode,
        globalId,
        { orderEntries },
        { fields: 'FULL', globalId },
      );
      const getAddToCartData = addOrderToCartData(response?.data?.cartModifications, currentOrder);
      const cartModificationsResponse: HttpResponse<ErrorResponse | CartModificationResponseData> =
        toLegacyResponse(response, getAddToCartData);
      return cartModificationsResponse;
    } catch (error) {
      const errorMapping = handleErrorResponse(error);
      return toLegacyResponse(error, errorMapping);
    }
  }

  public static async HandleDownloadEUSDocument({ orderCode }: { orderCode: string }) {
    const asm = Boolean(Cookies.get('asm'));
    const globalId = Cookies.get('globalId') || '';

    try {
      const response = await apim.downloadEusPdfDocument(SATAIR_BASESITE_ID, orderCode, globalId, {
        globalId,
        asm,
      });
      const { headers } = response ?? {};
      const FileDownloadName = getFileNameFromHeaders(headers);
      const processedResponse = await toLegacyDownloadResponse(response);
      const { ContentType, FileContents } = processedResponse ?? {};

      const Data = {
        FileContents,
        ContentType,
        FileDownloadName,
      };

      return toLegacyResponse(response, {
        Data,
      });
    } catch (error) {
      const notification = getNotificationMapping({
        title: 'Failed to download EUS document',
        description: `EUS Document download failed." Order: ${orderCode}`,
        type: NotificationTypes.Error,
      });

      const result = {
        error: defaultError,
        notification: [notification],
      };

      return toLegacyResponse(error, objectKeysToPascalCaseRecursive(result));
    }
  }

  public static async HandleInvoiceDownloads(settings: AxiosRequestConfig) {
    const documentIds = settings.data?.documentIds ?? [];
    const asm = Boolean(Cookies.get('asm'));
    const globalId = Cookies.get('globalId') || '';
    const title = documentIds.length > 1 ? 'Invoices' : 'Invoice';
    const defaultTitle = documentIds.length > 2 ? title : `${title}-${documentIds.join('-')}`;

    try {
      const response = await apim.downloadInvoiceDocument(
        SATAIR_BASESITE_ID,
        { documentIds },
        {
          asm,
          globalId,
        },
      );
      const { headers } = response ?? {};
      const filename = getFileNameFromHeaders(headers);
      const processedResponse = await toLegacyDownloadResponse(response);
      const { ContentType, FileContents } = processedResponse ?? {};
      const fileExtension = getMimeTypeExtension(ContentType);
      const FileDownloadName = filename ?? `${defaultTitle}.${fileExtension}`;

      const data = {
        FileContents,
        ContentType,
        FileDownloadName,
      };

      return toLegacyResponse(response, {
        Data: data,
      });
    } catch (error) {
      const notification = getNotificationMapping({
        title: 'Failed to download document.',
        description: `Document download failed. ${title}: ${documentIds.join(',')}`,
        type: NotificationTypes.Error,
      });

      const result = {
        error: defaultError,
        notification: [notification],
      };

      return toLegacyResponse(error, objectKeysToPascalCaseRecursive(result));
    }
  }

  public static async HandleDownloadAirbusCertificate({ certificate }: { certificate: string }) {
    const asm = Boolean(Cookies.get('asm'));
    const globalId = Cookies.get('globalId') || '';

    try {
      const response = await apim.downloadCertificateDocument(SATAIR_BASESITE_ID, certificate, {
        asm,
        globalId,
      });
      const { headers } = response ?? {};
      const filename = getFileNameFromHeaders(headers);
      const processedResponse = await toLegacyDownloadResponse(response);
      const { ContentType, FileContents } = processedResponse ?? {};
      const fileExtension = getMimeTypeExtension(ContentType);
      const FileDownloadName = filename ?? `${certificate}.${fileExtension}`;

      const data = {
        FileContents,
        ContentType,
        FileDownloadName,
      };

      return toLegacyResponse(response, {
        Data: data,
      });
    } catch (error) {
      const notification = getNotificationMapping({
        title: 'Failed to download document.',
        description: `Document download failed. Certificate boxnumber: ${certificate}`,
        type: NotificationTypes.Error,
      });

      const result = {
        error: defaultError,
        notification: [notification],
      };

      return toLegacyResponse(error, objectKeysToPascalCaseRecursive(result));
    }
  }

  public static async HandleCustomsInvoice({ orderCode }: { orderCode: string }) {
    const asm = Boolean(Cookies.get('asm'));
    const globalId = Cookies.get('globalId') || '';

    try {
      const response = await apim.downloadCustomsInvoiceDocument1(
        SATAIR_BASESITE_ID,
        orderCode,
        '_',
        {
          asm,
          globalId,
        },
      );
      const { headers } = response ?? {};
      const filename = getFileNameFromHeaders(headers);
      const processedResponse = await toLegacyDownloadResponse(response);
      const { ContentType, FileContents } = processedResponse ?? {};
      const fileExtension = getMimeTypeExtension(ContentType);
      const FileDownloadName = filename ?? `${orderCode}.${fileExtension}`;

      const data = {
        FileContents,
        ContentType,
        FileDownloadName,
      };

      return toLegacyResponse(response, {
        Data: data,
      });
    } catch (error) {
      const notification = getNotificationMapping({
        title: 'The customs invoice could not be downloaded. Try again later',
        description: `Custom invoice document download failed. order code: ${orderCode}`,
        type: NotificationTypes.Error,
      });

      const result = {
        error: defaultError,
        notification: [notification],
      };

      return toLegacyResponse(error, objectKeysToPascalCaseRecursive(result));
    }
  }
}
