<template>
  <StandardDialogContainer
    :size="size"
    :border-line="borderLine"
    :use-padding="usePadding"
    :loading="loading"
    :has-close-button="hasCloseButton"
  >
    <div class="standard-dialog-modal__content" @scroll="onScroll">
      <div class="standard-dialog-modal__content-inner">
        <div
          class="standard-dialog-modal__header"
          v-ui-test="uiTestNameHeader"
          :class="{ 'stand-alone': !$slots.body && !$slots.actions }"
          v-if="$slots.header"
        >
          <slot name="header" />
        </div>
        <div
          class="standard-dialog-modal__body"
          v-if="$slots.body"
          :class="{ 'no-actions': !$slots.actions, 'not-active': loading }"
        >
          <slot name="body" />
        </div>
        <div
          class="standard-dialog-modal__actions"
          :class="actionButtonAlignmentComputed"
          v-if="$slots.actions"
        >
          <VButton
            v-if="hasCancelButton"
            :type="uiVariant.Secondary"
            input-type="button"
            v-ui-test="'modal-cancel'"
            @click="closeModal"
            >{{ textK('UI_COMMON_CANCEL') }}</VButton
          >
          <slot name="actions" />
        </div>
      </div>
    </div>
  </StandardDialogContainer>
</template>

<style lang="scss" src="./StandardDialogModal.scss" scoped></style>

<script lang="ts" setup>
import StandardDialogContainer from '@/src/core/components/modals/ui/standard-dialog-layout/standard-dialog-container.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useModalStore } from '@/src/core/stores/modal';
import { ScrollEvent, ScrollEventBus } from '@/src/core/utils/scroll-event-bus';
import { computed, onMounted, watch } from 'vue';

const textK = useText();
const modalStore = useModalStore();
const uiVariant = UiVariant;

interface Props {
  hasCloseButton?: boolean;
  hasCancelButton?: boolean;
  usePadding?: boolean;
  size?: UiSize;
  borderLine?: UiVariant;
  loading?: boolean;
  lockedModal?: boolean;
  uiTestNameHeader?: string;
  actionButtonAlignment?: 'left' | 'center' | 'right';
}

const props = withDefaults(defineProps<Props>(), {
  hasCloseButton: true,
  hasCancelButton: false,
  usePadding: true,
  size: UiSize.Medium,
  borderLine: UiVariant.Default,
  loading: false,
  lockedModal: false,
  uiTestNameHeader: 'standard-dialog-header',
  actionButtonPosition: 'left',
});

const closeModal = ($event: Event) => {
  $event.preventDefault();
  modalStore.closeModal();
};

const onScroll = ($event: Event) => {
  const targetElement = $event.target as HTMLElement;
  const scrollPosition = targetElement ? targetElement.scrollTop : window.scrollY;
  ScrollEventBus.$emit('modalScroll', { scrollPosition, originalEvent: $event } as ScrollEvent);
};

const lockModal = (shouldLock: boolean) => {
  if (modalStore.isVisible) {
    modalStore.lockCurrentModal(shouldLock);
  }
};

const actionButtonAlignmentComputed = computed(() => ({
  'standard-dialog-modal__actions--center': props.actionButtonAlignment === 'center',
  'standard-dialog-modal__actions--right': props.actionButtonAlignment === 'right',
}));

watch(
  () => props.loading,
  (isLoading) => {
    if (isLoading) {
      lockModal(true);
    } else if (!props.lockedModal) {
      lockModal(false);
    }
  },
  { immediate: true },
);

onMounted(() => {
  lockModal(props.lockedModal);
});
</script>
