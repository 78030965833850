import { CancelReqCategory, Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { Order, OrderChangeLogItem, OwningSystem } from '@/src/core/types/api';
import { AirbusExtendedOrderDetails } from '@/src/core/types/interfaces';
import { OrderApi } from '@/src/market/api/orderApi';
import { defineStore } from 'pinia';

interface OrderDetailsState {
  order: Order;
  updates: OrderChangeLogItem[];
  isBusy: boolean;
  isUpdatesBusy: boolean;
}

export const useOrderDetailsStore = defineStore('orderDetails', {
  state: (): OrderDetailsState => ({
    order: {} as Order,
    updates: [],
    isBusy: false,
    isUpdatesBusy: false,
  }),
  getters: {
    airbusDeliveries(state) {
      if (state.order.OwningSystem === OwningSystem.BLUE) {
        if (
          state.order.AirbusExtendedOrderDetails &&
          state.order.AirbusExtendedOrderDetails.entries &&
          state.order.AirbusExtendedOrderDetails.entries.length > 0
        ) {
          const deliveries = state.order.AirbusExtendedOrderDetails.entries.flatMap((entry) => {
            return entry.deliveries;
          });
          return deliveries;
        }
      }
      return [];
    },
    airbusBoxUnitId(): string[] | undefined {
      return this.airbusDeliveries?.flatMap(({ boxes }) =>
        boxes
          .filter(({ isCertificateAvailable }) => isCertificateAvailable)
          .map(({ externalHandlingUnitId }) => externalHandlingUnitId),
      );
    },
    airbusInvoiceNumbers(): string[] | undefined {
      return this.airbusDeliveries?.flatMap(({ documents }) =>
        documents.map(({ invoiceNumber }) => invoiceNumber),
      );
    },
    hasCustomsInvoice(): boolean {
      return (this.order.AirbusExtendedOrderDetails as AirbusExtendedOrderDetails)
        ?.customInvoiceAvailable;
    },
    orderEntryNumber(): string {
      return (this.order.AirbusExtendedOrderDetails as AirbusExtendedOrderDetails)?.entries[0]
        ?.orderEntryNumber;
    },
  },
  actions: {
    currentOrderUpdated(payload: { Data: Order }) {
      this.order = Object.assign({}, payload.Data);
      Object.freeze(this.order);
    },
    updatesUpdated(payload: { Data: OrderChangeLogItem[] }) {
      this.updates = [];
      this.updates.push(...payload.Data);
      Object.freeze(this.updates);
    },
    async fetchOrderDetails(payload: { orderCode: string; silent?: boolean }) {
      if (!payload.silent) {
        this.isBusy = true;
      }

      const { IsSuccess, Data } = await Req(
        {
          url: OrderApi.OrderInfo(payload.orderCode),
        },
        new ReqQueue(ReqQueueTypes.Default, 'OrderDetails'),
        OrderApi.HandleOrderDetails.bind(OrderApi, {
          orderCode: payload.orderCode,
        }),
      );

      if (IsSuccess && Data) {
        this.currentOrderUpdated({ Data });
      }

      if (!payload.silent) {
        this.isBusy = false;
      }

      return { IsSuccess };
    },
    async fetchOrderUpdates(payload: { orderCode: string; silent?: boolean }) {
      if (!payload.silent) {
        this.isUpdatesBusy = true;
      }

      const { IsSuccess, Data } = await Req(
        {
          url: OrderApi.GetOrderLog(payload.orderCode),
        },
        new ReqQueue(ReqQueueTypes.Default, 'OrderUpdates'),
      );

      if (IsSuccess) {
        this.updatesUpdated({ Data: Data.Changes });
      }

      if (!payload.silent) {
        this.isUpdatesBusy = false;
      }

      return { IsSuccess };
    },
    async reset() {
      this.currentOrderUpdated({ Data: {} as Order });
      this.updatesUpdated({ Data: [] });
      CancelReqCategory('OrderDetails');
      CancelReqCategory('OrderUpdates');
    },
  },
});
