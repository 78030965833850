<template>
  <div class="contextual-actions" v-click-outside="close">
    <button
      class="button-invisible contextual-action-button"
      @click="toggleDropdown"
      v-ui-test="uiTestNameButton"
    >
      <svgicon name="ui-context-actions"></svgicon>

      <Dropdown
        :is-product-card="isProductCard"
        :mobile-bottom-position="true"
        :dropdown-style="true"
        v-if="open"
        @dropDownOpen="open = $event"
      >
        <slot />
      </Dropdown>
    </button>
  </div>
</template>

<style scoped src="./contextual-actions.scss" lang="scss"></style>

<script lang="ts" setup>
import Dropdown from '@/src/core/components/ui/dropdown/dropdown.vue';
import { useModalStore } from '@/src/core/stores/modal';
import { SearchEventBus } from '@/src/core/utils/search-event-bus';
import { computed, getCurrentInstance, ref, watch } from 'vue';

interface Props {
  isProductCard?: boolean;
  uiTestNameButton?: string;
}

withDefaults(defineProps<Props>(), {
  isProductCard: false,
  uiTestNameButton: 'contextual-action-button',
});
const emit = defineEmits(['open']);

const modalStore = useModalStore();
const vm = getCurrentInstance()?.proxy;

const open = ref(false);

const toggleDropdown = () => {
  open.value = !open.value;
  SearchEventBus.$emit('contextualClick');
};

const close = () => {
  open.value = false;
};

const isModalVisible = computed(() => {
  return modalStore.isVisible;
});

watch(
  () => open.value,
  (isOpen: boolean) => {
    emit('open', isOpen);
  },
);

watch(
  () => isModalVisible.value,
  (newVal: boolean) => {
    if (newVal) {
      close();
    }
  },
);

watch(
  () => vm?.$route.fullPath,
  () => {
    if (open.value) {
      close();
    }
  },
  { immediate: true, deep: true },
);
</script>
