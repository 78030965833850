<template>
  <div class="cta" @click="onClick">
    <template v-if="isAuthenticated">
      <transition name="fade" mode="out-in">
        <template v-if="productAdditionalData">
          <QuantityController
            v-if="ruleStatusOk"
            ref="quantityController"
            v-ui-test="'search-item-add-to-cart'"
            :product-id="productId"
            :has-add-to-cart="true"
            :bid-or-quote="allowBidAndQuote"
            :is-marketplace="isMarketplace"
            :is-airbus="isAirbusComputed"
            :procurement-type="procurementType"
            :min-value="product.AddToBasketQty"
            :step="product.CartStep"
            :unit="productSalesUnit"
          />
          <div v-if="isFhs" class="btn-wrapper">
            <RequestQuoteButton
              ui-test-tag="search-item-request-quote"
              :text="textK('UI_QUOTES_REQUEST_CTA')"
              class="product-button-login requests"
              :modal-params="{
                modalComponent: 'ModalRequestQuoteFHS',
                params: { id: productId, procurementType: procurementType },
              }"
            />
          </div>
          <div class="btn-wrapper" v-else-if="isRequestPartExchangeAvailable">
            <RequestQuoteButton
              ui-test-tag="search-item-request-part-exchange"
              :text="textK('UI_PROPRIETARY_PARTS_QUOTATION_REQUEST_EXCHANGE')"
              class="product-button-login requests"
              :modal-params="{
                modalComponent: 'ModalRequestExchange',
                params: {
                  id: productId,
                  procurementType: procurement?.procurementType,
                },
              }"
            />
          </div>
          <div class="btn-wrapper" v-else-if="isQuoteRequestPrice">
            <RequestQuoteButton
              ui-test-tag="search-item-request-price"
              :text="textK('UI_QUOTES_REQUEST_PRICE')"
              class="product-button-login requests"
              :modal-params="{
                modalComponent:
                  !isMarketplace && enableSatairQuotes
                    ? 'ModalCreateSingleLineQuote'
                    : 'ModalRequestQuote',
                params: {
                  id: productId,
                  sellerId: sellerId,
                  isMarketplace: isMarketplace,
                },
              }"
            />
          </div>
          <div v-else-if="isLeaseToolAndQuotable" class="btn-wrapper">
            <RequestQuoteButton
              ui-test-tag="search-item-request-tool-lease"
              :text="textK('UI_TOOL_QUOTATION_REQUEST_LEASE_HEADER')"
              class="product-button-login requests"
              :modal-params="{
                modalComponent: 'ModalRequestToolLease',
                params: {
                  id: productId,
                },
              }"
            />
          </div>
          <div v-else-if="isLeasePartAndQuotable" class="btn-wrapper">
            <RequestQuoteButton
              ui-test-tag="search-item-request-part-lease"
              class="product-button-login requests"
              :text="textK('UI_TOOL_QUOTATION_REQUEST_LEASE_QUOTE_BUTTON_TEXT')"
              :modal-params="{
                modalComponent: 'ModalRequestPartLease',
                params: {
                  id: productId,
                },
              }"
            />
          </div>
          <div v-else-if="isUsedAndQuotable" class="btn-wrapper">
            <RequestQuoteButton
              ui-test-tag="search-item-request-tool-used"
              :text="textK('UI_PRODUCT_REQUESTQUOTE')"
              class="product-button-login requests"
              :modal-params="{
                modalComponent: 'ModalRequestToolUsed',
                params: {
                  id: productId,
                  serialNumber: procurement?.serialNumber,
                },
              }"
            />
          </div>
          <div class="btn-wrapper" v-else-if="isAirbusPartRepairQuotableComputed">
            <RequestQuoteButton
              ui-test-tag="search-item-request-quote"
              :text="textK('UI_REPAIR_QUOTATION_REQUEST_CTA')"
              class="product-button-login requests"
              :modal-params="{
                modalComponent: 'ModalAirbusRepairRequestQuote',
                params: {
                  productId: productId,
                  procurementType: procurementType,
                  serialNumberRequired: product.SerialNumberRequired,
                },
              }"
            />
          </div>
          <div v-else-if="isQuoteRequest" class="btn-wrapper">
            <RequestQuoteButton
              ui-test-tag="search-item-request-quote"
              :text="textK('UI_QUOTES_REQUEST_CTA')"
              class="product-button-login requests"
              :modal-params="{
                modalComponent: requestQuoteModal,
                params: { id: productId, procurementType: procurementType },
              }"
            />
          </div>
          <div class="btn-wrapper" v-else>
            <VButton
              v-ui-test="'search-item-cta-disabled'"
              :type="uiVariant.Secondary"
              :size="uiSize.Auto"
              class="product-button-login unavailable"
              disabled
            >
              {{ ruleStatusBtnLabel }}
            </VButton>
          </div>
        </template>
        <VButton v-else class="cta-skeleton-loader">
          <DotLoader :loading="true" />
        </VButton>
      </transition>
    </template>
    <div class="product-item-cta" v-else>
      <div class="product-button">
        <VButton
          v-ui-test="'search-item-login'"
          :type="uiVariant.Primary"
          :size="uiSize.Auto"
          class="product-button-login"
          @click.stop="openModal({ modalComponent: 'ModalLogin' })"
        >
          <svgicon width="24px" height="24px" name="ui-login" class="product-price-login"></svgicon>
          {{ textK('UI_PRODUCT_LOGINCTA') }}
        </VButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./product-cta.scss" scoped></style>

<script lang="ts" setup>
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useModal from '@/src/core/hooks/useModal';
import useText from '@/src/core/hooks/useText';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { useAssistantStore } from '@/src/core/stores/assistant';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { Product, ProductPriceInfo } from '@/src/core/types/api';
import { IProductPriceEntry, Procurement } from '@/src/core/types/interfaces';
import { IeKeys } from '@/src/core/types/keys';
import { MaterialStatus } from '@/src/core/types/material-status';
import { useRoute } from '@/src/core/utils/router';
import RequestQuoteButton from '@/src/market/components/request-quote-button/request-quote-button.vue';
import QuantityController from '@/src/market/components/utils/quantity-controller/quantity-controller.vue';
import {
  hasNoPriceAvailable,
  isMaterialStatusQuotable,
} from '@/src/market/services/material-status';
import {
  isLeasePart,
  isLeaseTool,
  isUsedPart,
  isUsedTool,
} from '@/src/market/services/procurements';
import {
  isAirbus,
  isSatair,
  productAdditionalData as productAdditionalDataService,
} from '@/src/market/services/product-parts';
import {
  airbusProductNotQuotableOrSellable,
  crossPlantMaterialOutOfStock,
  hasNoPrice,
  isAirbusPartRepairQuotable,
  isQuotable,
  isRestricted,
  isSellable,
  limitedByCrossPlantMaterialStatus,
  materialStatus,
} from '@/src/market/services/product-rules';
import { useCartStore } from '@/src/market/stores/cart';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { Key } from 'ts-key-enum';
import {
  computed,
  defineProps,
  onMounted,
  onUnmounted,
  ref,
  toRefs,
  watch,
  withDefaults,
} from 'vue';

export interface Props {
  product: Product;
  procurement?: Procurement;
  isActive?: boolean;
  isFhs?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  isFhs: false,
});

const { product, procurement, isActive } = toRefs(props);

const textK = useText();
const openModal = useModal();
const productPriceInfoStore = useProductPriceInfoStore();
const assistantStore = useAssistantStore();
const authenticationStore = useAuthenticationStore();
const modalStore = useModalStore();
const cartStore = useCartStore();
const route = useRoute();

const uiVariant = UiVariant;
const uiSize = UiSize;
const quantityController = ref<typeof QuantityController | null>(null);

const productAdditionalData = computed(() =>
  productAdditionalDataService(product.value.Id, product.value.OwningSystem),
);

const productId = computed(() => product.value.Id);

const productSalesUnit = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    const sapCode = productPriceInfo.value?.SalesUnit?.Code;
    const productUnit = product.value?.Unit || '';
    return sapCode ?? productUnit;
  }

  if (isAirbus(productAdditionalData.value)) {
    return procurement?.value?.salesUnit?.sapCode || procurement?.value?.salesUnit?.code || '';
  }
  return '';
});

const productPriceInfo = computed<ProductPriceInfo>(() => productPriceData.value?.ProductPriceInfo);

const productPriceData = computed<IProductPriceEntry>(() =>
  productPriceInfoStore.priceDataByOfferId(product.value?.Id || ''),
);

const isAuthenticated = computed(() => authenticationStore.isAuthenticated);

const readOnlyAccount = computed(() => authenticationStore.readOnlyAccount);

const enableSatairQuotes = computed(() => hasFeature(FEATURES.SATAIR_QUOTES));

const enableAirbusPropPartsServiceQuotation = computed(() =>
  hasFeature(FEATURES.AIRBUS_PROP_PARTS_SERVICE_QUOTATION),
);

const procurementType = computed(
  () => procurement?.value && (procurement?.value.procurementType as ProcurementType),
);

const isMarketplace = computed(() => product.value.IsMarketplace);

const isAirbusComputed = computed(() =>
  Boolean(
    productAdditionalData.value &&
      'masterShopId' in productAdditionalData.value &&
      productAdditionalData.value?.masterShopId.toLowerCase() === 'airbus',
  ),
);

const isQuotableProduct = computed(() => {
  if (isMarketplace.value) {
    return false;
  }

  if (isSatair(productAdditionalData.value)) {
    return isQuotable(productAdditionalData.value) && enableSatairQuotes.value;
  }

  return isQuotable(productAdditionalData.value, procurement?.value);
});

const allowBidAndQuote = computed(() => {
  const isAllowedBidOrQuote =
    (isAuthenticated.value &&
      product.value &&
      productAdditionalData.value &&
      !ruleStatusRestricted.value &&
      (isQuotableProduct.value || product.value.AllowQuoteRequests)) ||
    false;

  if (isAirbusComputed.value) {
    return isAllowedBidOrQuote && procurement?.value?.procurementType == ProcurementType.New;
  }

  return isAllowedBidOrQuote;
});

const isDeleting = computed(() => cartStore.isDeleting);

const cartIsBusy = computed(() => cartStore.isBusy);

const sellerId = computed(() => {
  if (isSatair(productAdditionalData.value)) {
    return productAdditionalData.value.Warehouse?.Code;
  }

  return '';
});

const ruleStatusOk = computed(
  () => isSellable(productAdditionalData.value, procurement?.value) && !props.isFhs,
);

const ruleStatusNoPrice = computed(() =>
  hasNoPrice(productAdditionalData.value, procurement?.value),
);

const ruleStatusRestricted = computed(() =>
  isRestricted(productAdditionalData.value, procurement?.value),
);

const ruleStatusCpms = computed(
  () =>
    limitedByCrossPlantMaterialStatus(productAdditionalData.value, procurement?.value) &&
    !materialStatusNoPrice.value,
);

const ruleStatusCpms30 = computed(() =>
  crossPlantMaterialOutOfStock(productAdditionalData.value, procurement?.value),
);

const materialStatusUnavailable = computed(
  () =>
    materialStatus(MaterialStatus.STATUS_E, productAdditionalData.value) ||
    materialStatus(MaterialStatus.STATUS_S1, productAdditionalData.value) ||
    materialStatus(MaterialStatus.STATUS_ZERO, productAdditionalData.value),
);

const materialStatusNoPrice = computed(() => {
  if (isAirbus(productAdditionalData.value)) {
    return hasNoPriceAvailable(productAdditionalData.value);
  }

  return false;
});

const materialStatusQuotable = computed(() => {
  if (isAirbus(productAdditionalData.value)) {
    return isMaterialStatusQuotable(productAdditionalData.value);
  }

  return false;
});

const isAirbusPartRepairQuotableComputed = computed(() => {
  if (procurement?.value) {
    if (props.isFhs) {
      return false;
    }

    const isRepair = isAirbusPartRepairQuotable(
      procurement?.value,
      enableAirbusPropPartsServiceQuotation.value,
    );
    return isRepair && !props.isFhs;
  }
  return false;
});

const ruleStatusBtnLabel = computed(() => {
  if (props.isFhs) {
    return textK('UI_PRODUCT_RESTRICTED_BUTTON');
  } else if (materialStatusQuotable.value) {
    return textK('UI_PRODUCT_CONTACT_TO_QUOTE');
  } else if (
    !isAirbusPartRepairQuotableComputed.value &&
    procurementType.value === ProcurementType.Repair
  ) {
    return textK('UI_REPAIR_QUOTATION_DISABLED_CTA');
  } else if (
    ruleStatusRestricted.value ||
    ruleStatusCpms.value ||
    ruleStatusCpms30.value ||
    airbusProductNotQuotableOrSellableComputed.value ||
    materialStatusUnavailable.value
  ) {
    return textK('UI_PRODUCT_RESTRICTED_BUTTON');
  } else {
    if (ruleStatusNoPrice.value || materialStatusNoPrice.value) {
      return textK('UI_PRODUCT_NOPRICE_BUTTON');
    }

    return textK('UI_PRODUCT_RESTRICTED_BUTTON');
  }
});

const isQuoteRequestPrice = computed(
  () =>
    ruleStatusNoPrice.value &&
    !readOnlyAccount.value &&
    allowBidAndQuote.value &&
    (enableSatairQuotes.value || isMarketplace.value) &&
    !props.isFhs,
);

const isExchangeProcurement = computed(
  () =>
    procurement?.value &&
    (procurement?.value.procurementType === ProcurementType.ExchangeUsed ||
      procurement?.value.procurementType === ProcurementType.ExchangeNew),
);

const isPropietaryPart = computed(() => product.value.AirbusProprietary);

const isRequestPartExchangeAvailable = computed(
  () =>
    enableAirbusPropPartsServiceQuotation.value &&
    isQuotableProduct.value &&
    isExchangeProcurement.value &&
    isPropietaryPart.value &&
    !props.isFhs,
);

const isQuoteRequest = computed(
  () =>
    !ruleStatusRestricted.value &&
    allowBidAndQuote.value &&
    !readOnlyAccount.value &&
    (enableSatairQuotes.value || isMarketplace.value) &&
    !props.isFhs,
);

const isLeaseToolAndQuotable = computed(
  () =>
    isLeaseTool(procurement?.value?.procurementType, product.value) &&
    isQuotableProduct.value &&
    !props.isFhs,
);

const isLeasePartAndQuotable = computed(
  () =>
    isLeasePart(procurement?.value?.procurementType, product.value) &&
    isQuotableProduct.value &&
    !props.isFhs,
);

const isUsedAndQuotable = computed(
  () =>
    (isUsedPart(procurement?.value?.procurementType, product.value) ||
      isUsedTool(procurement?.value?.procurementType, product.value)) &&
    isQuotableProduct.value &&
    !props.isFhs,
);

const airbusProductNotQuotableOrSellableComputed = computed(
  () =>
    airbusProductNotQuotableOrSellable(productAdditionalData.value, procurement?.value) &&
    !materialStatusNoPrice.value,
);

const isDisabled = computed(
  () => !ruleStatusOk.value || !isQuoteRequestPrice.value || !isQuoteRequest.value,
);

const requestQuoteModal = computed(() => {
  if (!isMarketplace.value && enableSatairQuotes.value) {
    return 'ModalCreateSingleLineQuote';
  }

  return 'ModalRequestQuote';
});

const onClick = (event: Event) => {
  if (isDisabled.value) {
    event.stopPropagation();
  }
};

const onKeyPressed = ($event: KeyboardEvent) => {
  const key: string = $event.key;
  if (route.name !== 'search' || assistantStore.assistantActive || modalStore.isVisible) {
    return;
  }
  switch (key) {
    case Key.ArrowLeft:
    case IeKeys.ArrowLeft:
      $event.preventDefault();
      if (
        isAuthenticated.value &&
        !isDeleting.value &&
        !cartIsBusy.value &&
        quantityController.value?.qty > 0
      ) {
        quantityController.value?.$refs?.quantitySelector?.decrease();
      }
      break;
    case Key.ArrowRight:
    case IeKeys.ArrowRight:
      $event.preventDefault();
      if (isAuthenticated.value && !isDeleting.value) {
        quantityController.value?.$refs?.quantitySelector?.increase();
      }
      break;
  }
};

watch(
  () => isActive.value,
  (value: boolean, oldValue: boolean) => {
    if (value && !oldValue) {
      window.addEventListener('keydown', onKeyPressed);
    } else if (!value && oldValue) {
      window.removeEventListener('keydown', onKeyPressed);
    }
  },
);

onMounted(() => {
  if (isActive.value) {
    window.addEventListener('keydown', onKeyPressed);
  }
});

onUnmounted(() => {
  window.removeEventListener('keydown', onKeyPressed);
});
</script>
