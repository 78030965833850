import { AxiosRequestConfig } from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import DownloadFile from '../services/download';
import { TextKey } from '../services/text-key';
import { NotificationTypes } from '../types/api';
import { useNotificationsStore } from './notifications';
import { ReqQueue } from '@/src/core/services/requester';
import { HttpResponse } from '@/src/core/apim';

const waitTime = 4000;

export const useDownloadsStore = defineStore('downloads', () => {
  const notificationsStore = useNotificationsStore();
  const pendingRequests = ref<string[]>([]);

  const pendingAdd = (payload: { requestId: string }) => {
    if (pendingRequests.value.find((requestId) => requestId === payload.requestId)) {
      return;
    }

    pendingRequests.value.push(payload.requestId);
  };

  const pendingRemove = (payload: { requestId: string }) => {
    pendingRequests.value.splice(
      pendingRequests.value.findIndex((requestId) => requestId === payload.requestId),
      1,
    );
  };

  const reset = () => {
    pendingRequests.value = [];
  };

  async function downloadFile<T extends { [key: string]: any } = any>(
    axiosRequestConfig: AxiosRequestConfig,
    queueSettings: ReqQueue = new ReqQueue(),
    apimHandler?: (settings: AxiosRequestConfig) => Promise<HttpResponse<T>>,
  ) {
    // For airbus invoices that have the documentId in data since it's POST request
    const airbusInvoiceId =
      axiosRequestConfig.data?.documentIds && axiosRequestConfig.data.documentIds[0];
    const requestId = airbusInvoiceId ? airbusInvoiceId : axiosRequestConfig.url;

    if (!axiosRequestConfig.url) {
      return { IsSuccess: false };
    }
    const timer: number = window.setTimeout(() => {
      notificationsStore.addNotification({
        notificationId: 'pendingDownload' + axiosRequestConfig.url,
        notificationItem: {
          Title: TextKey('UI_COMMON_PENDING_DOWNLOAD_TITLE'),
          Description: TextKey('UI_COMMON_PENDING_DOWNLOAD_DESCRIPTION'),
          Type: NotificationTypes.Information,
          Timing: 9999000,
        },
      });
    }, waitTime);
    pendingAdd({ requestId });
    const { IsSuccess, Data } = await DownloadFile(axiosRequestConfig, queueSettings, apimHandler);
    window.clearTimeout(timer);
    notificationsStore.removeNotificationById('pendingDownload' + axiosRequestConfig.url);
    pendingRemove({ requestId });
    return { IsSuccess, Data };
  }

  const cancelDownload = async (payload: { url: string }) => {
    return pendingRemove({ requestId: payload.url });
  };

  const isDownloading = (filename: string) => {
    return pendingRequests.value.findIndex((requestId) => filename === requestId) > -1;
  };

  return {
    isDownloading,
    cancelDownload,
    downloadFile,
    reset,
  };
});
