// tslint:disable
import { apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { getNotificationMapping } from '@/src/core/services/notification-mapping';
import { ListType, NotificationTypes } from '@/src/core/types/api';
import {
  objectKeysToCamelCaseRecursive,
  objectKeysToPascalCaseRecursive,
} from '@/src/core/utils/object';
import { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class ListApi {
  public static PreviewImportList = `${baseUrl}/api/previewimportlist`;
  public static ImportList = `${baseUrl}/api/importlist`;
  public static AllLists = `${baseUrl}/api/lists`;
  public static RecentLists = `${baseUrl}/api/lists/recent`;
  public static CreateList = `${baseUrl}/api/lists/createlist`;
  public static AllListPaging = (page: number, size: number = 20) =>
    `${baseUrl}/api/lists?size=${size}&page=${page ? page : '0'}`;
  public static GetList = (listCode: string) => `${baseUrl}/api/lists/${listCode}`;
  public static UpdateListEntry = (listCode: string, productId: string) =>
    `${baseUrl}/api/lists/${listCode}/entry/${productId}`;
  public static UpdateListEntries = (listCode: string, productId: string) =>
    `${baseUrl}/api/lists/${listCode}/entries/${productId}`;
  public static AddListEntries = (listCode: string, productId: string) =>
    `${baseUrl}/api/lists/${listCode}/add/${productId}`;
  public static DeleteEntry = (listCode: string, productId: string) =>
    `${baseUrl}/api/lists/${listCode}/delete/${productId}`;
  public static RenameList = (listCode: string, listName: string, type: ListType) =>
    `${baseUrl}/api/lists/rename/${listCode}/${listName}/${type}`;
  public static DeleteList = (listCode: string) => `${baseUrl}/api/lists/delete/${listCode}`;
  public static ClearUnresolvedEntries = (listCode: string) =>
    `${baseUrl}/api/lists/${listCode}/clearunresolved/`;
  public static ClearUnresolvedEntry = (listCode: string, pno: string, manu?: string) =>
    `${baseUrl}/api/lists/${listCode}/clearunresolvedentry/?pno=${pno}&manu=${manu}`;
  public static ProductSuggestions = (pno: string) =>
    `${baseUrl}/api/lists/productsuggestions/?pno=${pno}`;
  public static SelectProductSuggestion = (
    listCode: string,
    productId: string,
    quantity: number,
    pno: string,
    manu?: string,
  ) =>
    `${baseUrl}/api/lists/${listCode}/selectunresolved/?productId=${productId}&quantity=${quantity}&pno=${pno}&manu=${manu}`;
  public static DownloadProductListDocument = (listCode: string) =>
    `${baseUrl}/api/lists/${listCode}/download`;

  public static SaveAsList = `${baseUrl}/api/lists/saveaslist`;
  public static AddListToCart = (listCode: string, cartCode: string) =>
    `${baseUrl}/api/lists/addtocart/${listCode}/${cartCode}`;

  public static MultiLineOrderList = (listName: string) =>
    `${baseUrl}/api/lists/multiline/${listName}`;
  public static MultiLineOrderPasteRaw = (listCode: string) =>
    `${baseUrl}/api/lists/${listCode}/processtext`;

  //QuoteDraft
  public static AllQuoteLists = `${baseUrl}/api/lists`;
  //QuoteList
  public static GetQuoteList = `${baseUrl}/api/quotelist`;
  public static CreateQuoteFromList = `${baseUrl}/api/quotelist/create`;

  public static async handleCreateQuoteFromList(settings: AxiosRequestConfig) {
    const userId = Cookies.get('globalId') || '';
    const createQuote = objectKeysToCamelCaseRecursive(settings.data.createQuote);

    if (createQuote.requestedDate === '') {
      delete createQuote['requestedDate'];
    }

    const resp = await apim.validate('satairb2b', userId, createQuote.quoteItems, {
      asm: Cookies.get('asm') === 'true',
    });

    if (resp.data.listValid) {
      const quote = await apim.createSatairQuote('satairb2b', userId, createQuote, {
        asm: Cookies.get('asm') === 'true',
        globalId: userId,
      });

      const notification = getNotificationMapping({
        title: 'Your quote request was submitted.',
        notificationAction: {
          Name: 'Follow your request',
          Url: `/market/quotes/${quote.data.code}`,
        },
        type: NotificationTypes.Success,
      });

      const result = {
        data: 'Ok',
        notification: [notification],
      };

      return toLegacyResponse(quote, objectKeysToPascalCaseRecursive(result));
    }

    const notification = getNotificationMapping({
      title: 'Your quote request failed.',
      type: NotificationTypes.Error,
    });

    const result = {
      data: 'Failed',
      notification: [notification],
    };

    return toLegacyResponse(resp, objectKeysToPascalCaseRecursive(result));
  }
}
