import { apim, InvoiceItemsRequest, OpenItemsRequest } from '@/src/core/apim';
import { AxiosRequestConfig } from 'axios';
import { getEnv } from '@/src/core/services/environment';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { toLegacyDownloadResponse } from '@/src/core/services/legacy-download-response';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { AccountStatementRequest, FileTypes } from '@/src/core/types/api';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import {
  getAccountStatementInvoiceItemListWithPriceMapping,
  getBillingDocumentInfoListWithPriceMapping,
} from '@/src/finance/services/legacy-mapping-finance';
import { getNotificationMapping } from '@/src/core/services/notification-mapping';
import { SATAIR_BASESITE_ID } from '@/src/market/settings/apim';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class FinanceApi {
  public static GetDocuments = `${baseUrl}/api/finance/documents`;

  public static async handleGetDocuments(settings: AxiosRequestConfig) {
    const data = settings.data;
    const masterUnitId = data.unitId;
    const globalId = Cookies.get('globalId');
    const asm = Boolean(Cookies.get('asm'));

    const response = await apim.searchBillingDocumentInfo(SATAIR_BASESITE_ID, masterUnitId, data, {
      globalId,
      fields: 'FULL',
      asm,
    });

    const lists = getBillingDocumentInfoListWithPriceMapping(response.data);

    return toLegacyResponse(response, {
      Data: objectKeysToPascalCaseRecursive(lists),
    });
  }

  // DownloadDocuments (BULK) Example data (METHOD: POST)
  // documentsRequest[{DocCat: string, DocNum: string}]
  public static DownloadDocuments = `${baseUrl}/api/finance/documents/download`;
  public static async handleDownloadDocuments(settings: AxiosRequestConfig) {
    const requestEntries = settings.data;
    const globalId = Cookies.get('globalId');
    const asm = Boolean(Cookies.get('asm'));
    const now = dayjs(new Date()).format('YYYY-MM-DD');
    const fileName =
      requestEntries.length > 1 ? `Billing-documents-${now}` : requestEntries[0].formattedDocNum;
    try {
      const response = await apim.downloadBillingDocuments1(
        SATAIR_BASESITE_ID,
        { requestEntries },
        { globalId, asm },
      );

      const processResponse = await toLegacyDownloadResponse(response, fileName);
      return toLegacyResponse(response, {
        Data: processResponse,
      });
    } catch (err) {
      const error = { hasError: true, fatalError: true };
      const notification = getNotificationMapping({
        closeDrawer: false,
        description: `Document download failed. ${fileName}`,
        title: 'Failed to download document',
        type: 'Error',
      });
      const result = {
        notification: [notification],
        error,
        data: null,
      };
      return toLegacyResponse(err, objectKeysToPascalCaseRecursive(result));
    }
  }

  public static ShowAccountStatement = `${baseUrl}/api/finance/account-statement`;
  public static async handleGetAccountStatement(settings: AxiosRequestConfig) {
    const params = settings.data;
    const masterUnitId = params.unitId;
    const globalId = Cookies.get('globalId');
    const asm = Boolean(Cookies.get('asm'));
    let response;

    if (!params.openItems && !params.overdueItems) {
      response = await apim.getAccountStatements(SATAIR_BASESITE_ID, masterUnitId, params, {
        globalId,
        fields: 'FULL',
        asm,
      });
    } else {
      const now = dayjs(new Date()).format('YYYY-MM-DD');
      response = await apim.getOpenItems(
        'satairb2b',
        masterUnitId,
        { date: now, includeOnlyOverdueItems: params.overdueItems },
        {
          globalId,
          fields: 'FULL',
          asm,
        },
      );
    }

    const invoices = getAccountStatementInvoiceItemListWithPriceMapping(response.data);

    return toLegacyResponse(response, {
      Data: objectKeysToPascalCaseRecursive(invoices),
    });
  }

  public static DownloadAccountStatement = `${baseUrl}/api/finance/account-statement/download`;
  public static async handleDownloadAccountStatement(settings: AxiosRequestConfig) {
    const params = settings.data as AccountStatementRequest;
    const globalId = Cookies.get('globalId');
    const asm = Boolean(Cookies.get('asm'));
    const baseSiteId = SATAIR_BASESITE_ID;
    const masterB2BUnitId = params.UnitId;
    let response, fileName;
    if (!params.OpenItems && !params.OverdueItems) {
      const invoiceItemRequest: InvoiceItemsRequest = {
        fromDate: params.FromDate,
        toDate: params.ToDate,
      };
      fileName = `${params.FromDate}-to-${params.ToDate}`;
      if (params.FileType === FileTypes.excel) {
        response = await apim.getAccountStatementsAsExcel(
          baseSiteId,
          masterB2BUnitId,
          invoiceItemRequest,
          { globalId, asm },
        );
      } else {
        response = await apim.getAccountStatementsPdf(
          baseSiteId,
          masterB2BUnitId,
          invoiceItemRequest,
          { globalId, asm },
        );
      }
    } else {
      const now = dayjs(new Date()).format('YYYY-MM-DD');
      fileName = params.OverdueItems ? `${now}-open-item-with-overdue` : `${now}-openitems`;
      const openItemsRequest: OpenItemsRequest = {
        date: now,
        includeOnlyOverdueItems: params.OverdueItems,
      };
      if (params.FileType === FileTypes.excel) {
        response = await apim.getOpenItemsAsExcel(baseSiteId, masterB2BUnitId, openItemsRequest, {
          globalId,
          asm,
        });
      } else {
        response = await apim.getOpenItemsAsPdf(baseSiteId, masterB2BUnitId, openItemsRequest, {
          globalId,
          asm,
        });
      }
    }

    const processResponse = await toLegacyDownloadResponse(response, fileName);

    return toLegacyResponse(response, {
      Data: processResponse,
    });
  }
  public static GetAirbusInvoices = `${baseUrl}/api/finance/airbus-invoicelist`;
  public static async handleGetAirbusInvoices(settings: AxiosRequestConfig) {
    const params = settings.data;
    const globalId = Cookies.get('globalId');
    const asm = Boolean(Cookies.get('asm'));
    const response = await apim.getInvoiceList(SATAIR_BASESITE_ID, params, {
      asm,
      globalId,
      fields: 'FULL',
    });

    const data = { Data: response.data };
    return toLegacyResponse(response, data);
  }
}
