import { ProductRule } from '@/src/core/apim';

/**
 * Maps product rules to stock, price visibility, order button visibility, and rule status.
 *
 * @param {ProductRule} productRule - The SAP CC product rule object.
 * @returns {object} - An object containing showStock, showPrice, showOrderButton, and ruleStatus.
 */
export const getProductRuleMapping = (productRule: ProductRule) => {
  const showStock = productRule?.stockVisible === 'YES';
  const showPrice = productRule?.priceVisible === 'YES';
  const showOrderButton = productRule?.sellable === 'YES';
  const ruleStatus = showOrderButton
    ? 'OK'
    : productRule?.sellable === 'LIMITED_BY_CROSS_PLANT_MATERIAL_STATUS' && showPrice
    ? 'Out of stock'
    : productRule?.sellable === 'LIMITED_BY_CROSS_PLANT_MATERIAL_STATUS'
    ? 'CPMS'
    : productRule?.sellable === 'NO_PRICE_AVAILABLE'
    ? 'NOPRICE'
    : productRule?.sellable === 'NO_PRICE_AVAILABLE_BUT_QUOTABLE'
    ? 'NOPRICE'
    : productRule?.sellable === 'CROSS_PLANT_MATERIAL_STATUS_OUT_OF_STOCK'
    ? 'CPMS30'
    : 'Restricted';

  return {
    showStock,
    showPrice,
    showOrderButton,
    ruleStatus,
  };
};
